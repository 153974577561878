<template>
    <item-list
        ref="list"
        :endpoint="endpoint"
        :itemComponent="CobrandedCard"
        :emptyText="$t('supplier.noCobrandedSupplierFound')"
        itemWrapperClass="row"
        itemClass="col-md-6"
    ></item-list>
</template>

<script>
    import { shallowRef } from "vue";
    import { endpoints } from "@/components/common/api-config.js";
    import { useMainStore } from "@/main-store";
    import ItemList from "@/components/common/ItemList.vue";
    import CobrandedCard from "./CobrandedCard.vue";

    export default {
        name: "CobrandedList",
        components: { ItemList },
        props: {
            id: {
                type: String,
                default: "cobranded-list",
            },
        },
        data: function () {
            return {
                CobrandedCard: shallowRef(CobrandedCard),
                mainStore: useMainStore(),
            };
        },
        computed: {
            endpoint() {
                return endpoints["cobrandedsupplier-list"](this.mainStore.profile.pk);
            },
        },
    };
</script>
