<template>
    <p>Preview in</p>
    <app-tabs v-cloak @update="switchApps" :available-apps="apps"></app-tabs>
    <div class="preview-container mt-2" v-if="app">
        <post-preview-facebook v-if="app === 'facebook'" :post="post"></post-preview-facebook>
        <post-preview-instagram v-if="app === 'instagram'" :post="post"></post-preview-instagram>
        <post-preview-linkedin v-if="app === 'linkedin'" :post="post"></post-preview-linkedin>
    </div>
    <div v-else>
        <p class="mt-4 mb-4 text-center">Please select a social network to preview</p>
    </div>
</template>
<script>
    import AppTabs from '@/components/dashboard/AppTabs.vue';
    import PostPreviewFacebook from './PostPreviewFacebook.vue';
    import PostPreviewInstagram from './PostPreviewInstagram.vue';
    import PostPreviewLinkedIn from './PostPreviewLinkedIn.vue';

    export default {
        name: 'PostPreview',
        components: {
            AppTabs,
            PostPreviewFacebook,
            PostPreviewInstagram,
            'post-preview-linkedin': PostPreviewLinkedIn,
        },
        props: {
            post: {
                type: Object,
                required: true
            },
            apps: {
                type: Array,
                required: false
            },
        },
        data() {
            return {
                profile: window.profileData,
                app: null,
            }
        },
        methods: {
            switchApps(app) {
                this.app = app;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .preview-container {
        padding: 20px;
        background-color: #f0f2f5;
    }
</style>
