export const markHashTags = (message, useLink=false, app=null, extraClass=null) => {
    let link = '';
    let hashtagLink = '';
    const classLink = extraClass || '';

    switch(app) {
        case 'facebook':
            link = 'https://www.facebook.com/hashtag/';
            break;
        case 'instagram':
            link = 'https://www.instagram.com/explore/tags/';
            break;
        case 'linkedin':
            link = 'https://www.linkedin.com/feed/hashtag/?keywords=';
            break;
    }
    if(useLink && link !== '') {
        hashtagLink = `<a href="${link}$1" class="socialpost--message--hashtag ${classLink}" target="_blank">#$1</a>`;
    } else {
        hashtagLink = `<span class="socialpost--message--hashtag ${classLink}">#$1</span>`;
    }
    return message.replace(/#(\w+)/g, hashtagLink);
}
