import es from './es.json';
import en from './en.json';
import fr from './fr.json';

const messages = {
  "en": en,
  "es": es,
  "fr": fr
};

export default messages;
