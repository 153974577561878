<template>
    <confirm-and-wait-modal
        :contact="contact"
        :endpoint="endpoint"
        :id="id"
        :ok-title="$t('okTitle')"
        :successMessage="$t('successMessage')"
        :title="$t('title')"
        method="delete"
        ok-variant="danger"
    >
        <contact-basic-card
            :contact="contact"
            v-if="contact"
        ></contact-basic-card>
    </confirm-and-wait-modal>
</template>

<script>
    import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';
    import ContactBasicCard from './ContactBasicCard.vue';

    export default {
        components: { ConfirmAndWaitModal, ContactBasicCard },
        props: {
            id: {
                type: String,
                default: 'contact-delete-modal'
            },
            contact: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                endpoint: '',
            };
        },
        mounted() {
            this.endpoint = `/api/contacts/${this.contact.id}`
        },
        i18n: {
            messages: {
                en: {
                    title: "Delete the following contact?",
                    okTitle: "Delete",
                    successMessage: "Contact deleted",
                },
                es: {
                    title: "Eliminar el siguiente contacto?",
                    okTitle: "Eliminar",
                    successMessage: "Contacto eliminado",
                },
                fr: {
                    title: "Supprimer le contact suivant ?",
                    okTitle: "Supprimer",
                    successMessage: "Contact supprimé",
                },
            }
        }
    };
</script>
