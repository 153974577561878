<template>
    <confirm-and-wait-modal
        :id="id"
        :endpoint="selectPageEndpoint"
        method="post"
        :params="{ id: selectedPageId }"
        :ok-disabled="!selectedPageId"
        @cancel="resetState"
        :successMessage="textSelectSuccess"
        :title="textTitle"
        :okCallback="reload"
    >
        <endpoint-fetcher
            :endpoint="listPagesEndpoint"
            v-model:loading="loading"
            v-model:fetchedData="pages"
            v-model:thereIsNoData="noPagesAvailable"
        >
            <template v-if="pages && pages.length">
                <b-form-radio-group
                    id="pages-radio-form"
                    name="radio-btn-outline"
                    class="mb-3"
                    size="lg"
                    v-model="selectedPageId"
                    plain
                    stacked
                >
                    <b-form-radio
                        v-for="page in pages"
                        :disabled="page.taken && !isCurrent(page.id)"
                        :key="page.id"
                        :value="page.id"
                        class="mb-1 d-flex align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <img
                                :src="page.picture_url || page.image"
                                alt="Page Thumbnail"
                                class="mr-2"
                                style="width: 40px; height: 40px; border-radius: 50%;"
                            />
                            <span class="ml-1">
                                {{ page.name }} {{ page.taken ? isCurrent(page.id) ? textCurrent : texttaken : '' }}
                            </span>
                        </div>
                    </b-form-radio>
                </b-form-radio-group>
            </template>
            <accordion-item>
                <template #toggle>
                    {{ textFAQTitle }}
                </template>
                <template #content>
                    {{ textFAQSubtitle }}
                    <ul>
                        <li>{{ textFAQVerify1 }}</li>
                        <li>{{ textFAQVerify2 }}</li>
                        <li v-if="textFAQVerify3">{{ textFAQVerify3 }}</li>
                    </ul>
                </template>
            </accordion-item>
        </endpoint-fetcher>
    </confirm-and-wait-modal>
</template>

<script>
import { endpoints } from '@/components/common/api-config.js';
import EndpointFetcher from '@/components/common/EndpointFetcher.vue';
import ConfirmAndWaitModal from '@/components/common/ConfirmAndWaitModal.vue';
import AccordionItem from '@/components/common/AccordionItem.vue';

export default {
    name: 'page-selector-modal',
    components: {
        AccordionItem,
        ConfirmAndWaitModal,
        EndpointFetcher
    },
    props: {
        app: {
            type: String,
            required: true
        },
        id: {
            type: String,
            default: 'page-selector'
        },
    },
    data() {
        return {
            noPagesAvailable: false,
            pages: [],
            selectedPageId: null,
            loading: false,
        };
    },
    methods: {
        isCurrent(pageId) {
            return this.currentPageId === pageId;
        },
        checkOpenConnections() {
            const urlParams = new URLSearchParams(window.location.search);
            const connectingApp = urlParams.get('open_connections');
            if (connectingApp === this.app) {
                this.$bvModal.show(this.id);
            }
        },
        closeModal() {
            this.resetState();  // this will make the modal hide
        },
        reload() {
            window.location.href = window.location.origin + window.location.pathname;
        },
        resetState() {
            this.pages = [];
            this.selectedPageId = null;
            this.noPagesAvailable = false;
        },
    },
    computed: {
        profile() {
            return window.profileData ? window.profileData : {};
        },
        currentPageId() {
            const profile = window.profileData;
            const page = profile ? profile.applications[this.app] : null;
            return page ? page.data.page_id : ''
        },
        listPagesEndpoint() {
            return endpoints['profilesocial-pages'](this.profile.pk, this.app);
        },
        selectPageEndpoint() {
            return endpoints['profilesocial-select'](this.profile.pk, this.app, this.selectedPageId);
        },
        // Translations:
        textCurrent() {
            return '(' + this.$t(`common.current`) + ')';
        },
        texttaken(){
            return '(' + this.$t(`common.taken`) + ')';
        },
        textSelectSuccess() {
            return this.$t(`${this.app}.selectSuccess`);
        },
        textTitle() {
            return this.$t(`${this.app}.selectTitle`)
        },
        textFAQTitle() {
            return this.$t(`${this.app}.faqTitle`)
        },
        textFAQSubtitle() {
            return this.$t(`${this.app}.faqSubtitle`)
        },
        textFAQVerify1(){
            return this.$t(`${this.app}.faqVerify1`)
        },
        textFAQVerify2(){
            return this.$t(`${this.app}.faqVerify2`)
        },
        textFAQVerify3(){
            return this.$t(`${this.app}.faqVerify3`) || '';
        }
    },
    mounted() {
        this.checkOpenConnections();
        if (this.currentPageId) {
            this.selectedPageId = this.currentPageId;
        }
    },
    watch: {
        pages(newValue) {
            if (newValue && newValue.length == 1) {
                this.selectedPageId = newValue[0].id
            }
        }
    }
};
</script>
