<template>
    <b-overlay :show="isDisabled" rounded="lg" spinner-type="none">
        <confirm-and-wait ref="saveAdvancedFeatures" :endpoint="endpoint_advanced_features" :params="form" method="put">
            <div class="row">
                <div class="col-12">
                    <b-form>
                        <b-form-group :description="$t('profile.calendlyDescription')">
                            <label for="calendly_link">{{ $t('profile.calendlyHeader') }}</label>
                            <b-input type="text" class="form-control" id="calendly_link" v-model="form.calendly_link"
                                placeholder="https://calendly.com/agency"></b-input>
                        </b-form-group>

                        <b-form-group :description="$t('profile.hashtagSignatureDescription')">
                            <label for="hashtag_signature">{{ $t('profile.hashtagSignatureHeader') }}</label>
                            <b-input type="text" class="form-control" id="hashtag_signature"
                                v-model="form.hashtag_signature"
                                :placeholder="$t('profile.hashtagSignaturePlaceholder')"></b-input>
                        </b-form-group>

                        <b-form-group :label="$t('profile.hashtagAppSelectorHeader')">
                            <b-form-checkbox v-for="(app, index) in hashtag_allowed_apps" :key="index" :value="app.id"
                                v-model="form.hashtag_enabled_apps" :multiple="true">
                                {{ app.name }}
                            </b-form-checkbox>
                        </b-form-group>

                        <b-button class="btn-bu btn-bu-secondary btn-bu-no-icon" @click="saveAdvancedFeatures()">
                            {{ $t('common.saveChanges') }}
                        </b-button>
                    </b-form>
                </div>
            </div>
        </confirm-and-wait>
    </b-overlay>

    <endpoint-fetcher v-if="!isDisabled" class="d-none" :endpoint="endpoint_advanced_features" :process-response="displayAdvancedFeatures"
        :show-loading="false"></endpoint-fetcher>
</template>

<script>
import EndpointFetcher from '@/components/common/EndpointFetcher.vue';
import ConfirmAndWait from '@/components/common/ConfirmAndWait.vue';

import { endpoints } from '@/components/common/api-config.js';
import { connectionConfigValues } from '@/components/apps-connection-config.js';

const profile = window.profileData;

export default {
    name: 'AdvancedFeatures',
    components: {
        EndpointFetcher,
        ConfirmAndWait,
    },
    data() {
        return {
            profile: profile,
            endpoint_advanced_features: endpoints.profile_advanced_features(profile.pk),
            form: {
                calendly_link: null,
                hashtag_signature: null,
                hashtag_enabled_apps: [],
            },
            hashtag_allowed_apps: Object.values(connectionConfigValues).filter(app => app.hashtagEnabled),
            isPro: profile.plan === 'pro',
        }
    },
    methods: {
        displayAdvancedFeatures(response) {
            if (response.status === 200) {
                this.form.calendly_link = response.data.calendly_link;
                this.form.hashtag_signature = response.data.hashtag_signature;
                this.form.hashtag_enabled_apps = response.data.hashtag_enabled_apps;
            }
        },
        saveAdvancedFeatures() {
            if (this.form.calendly_link &&
                !this.form.calendly_link.startsWith('https://') &&
                !this.form.calendly_link.startsWith('http://')) {
                this.form.calendly_link = 'https://' + this.form.calendly_link;
            }
            this.$refs.saveAdvancedFeatures.submit();
        }
    },
    computed: {
        isDisabled() {
            return !this.isPro;
        }
    }
}
</script>
