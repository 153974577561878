<template>
    <b-modal :id='id' title="Upgrade your membership" centered>
        <p class="text-center mb-3">
            Upgrade your membership to PRO to connect this app!
        </p>
        <template #modal-footer>
            <b-button variant="primary" :href="upgradeURL">
                Upgrade
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { appStringProp } from '@/utils/props.js';
    import { views } from '@/components/common/views-config.js';

    export default {
        id: {
            type: String,
            default: 'upgrade-modal'
        },
        name: 'AppsConnectionItem',
        props: {
            app: appStringProp,
        },
        computed: {
            upgradeURL() {
                const profile = window.profileData;
                return views['profile_plan'](profile.slug);
            }
        }
    }
</script>
