<template>
    <item-list
        ref="list"
        :endpoint="generatedPostEndpoint"
        :itemComponent="GeneratedPost"
        :emptyComponent="GeneratedPostEmpty"
        >
    </item-list>
</template>

<script>
    import { shallowRef } from 'vue';
    import ItemList from '../../common/ItemList.vue';
    import GeneratedPost from './GeneratedPost.vue';
    import GeneratedPostEmpty from './GeneratedPostEmpty.vue';
    import { endpoints } from '@/components/common/api-config.js';

    export default {
        inheritAttrs: true,
        name: 'GeneratedPostList',
        components: {
            ItemList,
        },
        data() {
            return {
                generatedPostEndpoint: endpoints.generated_postings(window.profileData.pk),
                GeneratedPost: shallowRef(GeneratedPost),
                GeneratedPostEmpty: shallowRef(GeneratedPostEmpty),
            };
        },
        methods: {
            loadItems() {
                this.$refs.list.loadItems();
            }
        }
    }
</script>
