<template>

    <template v-if="currentStep === 'pick-post'">
        <post-library v-model:value="selectedPost"></post-library>
    </template>

    <template v-if="currentStep === 'edit-post'">
        <div class="row">
            <div class="col-xl-6">
                <template v-if="!selectSchedule">
                    <social-network-picker
                        v-model:value="form.apps"
                        label="Select the social networks you want to post to"
                        :selectAll="true"
                    ></social-network-picker>

                    <b-form-group label="Type post message" class="mt-2">
                        <b-textarea v-model="selectedPost.message" rows="6"></b-textarea>
                    </b-form-group>

                    <p>Date and time to schedule the post</p>
                    <date-picker v-model:value="form.schedule_date_time" clearable></date-picker>

                    <div class="text-right mt-4">
                        <b-button variant="outline-dark" @click="cancel()" class="mr-2">
                            Cancel
                        </b-button>

                        <template v-if="!form.schedule_date_time">
                            <b-button variant="primary" @click="schedule()" :disabled="!anyNetwork" class="mr-2">
                                <i class="fa fa-paper-plane mr-2"></i>
                                Post Now
                            </b-button>
                        </template>

                        <template v-else>
                            <b-button variant="primary" @click="schedule()" :disabled="!anyNetwork" class="mr-2">
                                <i class="fa fa-clock-o mr-2"></i>
                                Schedule
                            </b-button>
                        </template>

                        <confirm-and-wait-modal
                            id="schedule-modal"
                            :okCallback="okCallback"
                            :params="form"
                            :successMessage="$t('success')"
                            title="Creating posts"
                            :endpoint="postLibraryScheduleEndpoint"
                            method="post"
                            hide-footer
                            submitOnShow
                        >
                            <div class="text-center pt-4 pb-4">
                                <h4>Creating posts</h4>
                                <i v-for="app in form.apps" :key="app" :class="['fa', `fa-${app}`, 'fa-2x', 'mr-2']"></i>
                            </div>
                        </confirm-and-wait-modal>
                    </div>
                </template>


            </div>
            <div class="col-xl-6">
                <post-preview :post="selectedPost" :apps="form.apps"></post-preview>
            </div>
        </div>
    </template>

</template>
<script>
    import SocialNetworkPicker from '@/components/common/SocialNetworkPicker.vue';
    import PostLibrary from './PostLibrary.vue';
    import {endpoints} from '@/components/common/api-config.js';
    import {views} from '@/components/common/views-config.js';
    import PostPreview from './PostPreview.vue';
    import DatePicker from '@/components/common/DatePicker.vue';
    import ConfirmAndWaitModal from '@/components/common/ConfirmAndWaitModal.vue';

    export default {
        name: 'PostNew',
        components: {
            ConfirmAndWaitModal,
            DatePicker,
            PostLibrary,
            PostPreview,
            SocialNetworkPicker,
        },
        data() {
            return {
                profile: window.profileData,
                selectedPost: null,
                selectSchedule: false,
                form: {
                    apps: [],
                    schedule_date_time: null,
                    message: null,
                }
            }
        },
        computed: {
            postLibraryScheduleEndpoint() {
                return endpoints['post-library-schedule'](this.profile.pk, this.selectedPost.id);
            },
            anyNetwork() {
                // Check if there's at least one network selected
                return this.form.apps.length > 0;
            },
            currentStep(){
                return this.selectedPost ? 'edit-post' : 'pick-post'
            },
        },
        watch: {
            'selectedPost.message'() {
                if(this.selectedPost){
                    this.form.message = this.selectedPost.message;
                }
            }
        },
        methods: {
            schedule(){
                this.$bvModal.show('schedule-modal');
            },
            cancel(){
                this.selectedPost = null;
                this.schedule_date_time = null;
            },
            okCallback(response){
                if(response.data.scheduled_postings_ids.length > 0){
                    window.location = views.profile_calendar(this.profile.slug, this.form.schedule_date_time, response.data.scheduled_postings_ids);
                }
            },
        },
    }
</script>
