<template>
    <div class="d-flex justify-content-between align-items-center ml-4 mr-4 mb-4" style="height: 50px; width: 100%">
        <div class="d-flex align-items-center">
            <h3 style="font-weight: bold; color: #076fac; margin: 0; display: flex; align-items: center;">
                <i class="fa {{ campaignTypeIcon }} fa-2x icon mr-2"></i>
                {{ title }}
            </h3>
        </div>
        <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 200px; background: #076fac; height: 100%; border-radius: 5px" >
            <span class="text-center" style="color: white; font-size: 1.3em; font-weight: bold">
                {{ numToString(stats.count) }} Posts
            </span>
        </div>
    </div>
    <b-table-simple hover small responsive>
        <b-thead>
        <b-tr>
            <b-th colspan="1" class="header">Trade</b-th>
            <b-th colspan="3" class="header" style="background:#FAFAFA">Consumer Marketing</b-th>
            <b-th colspan="1" class="header">Sales</b-th>
            <b-th colspan="1" class="header" style="background:#FAFAFA">Investment</b-th>
        </b-tr>
        </b-thead>
        <b-tbody>
        <b-tr>
            <b-td>
                <stat-card
                    icon='fa-black-tie'
                    label='Agents (Avg)'
                    :value="numToString(stats.agents)"
                ></stat-card>
            </b-td>
            <b-td>
                <stat-card
                    icon='fa-users'
                    label='Total Reach'
                    :value="isLinkedIn ? NAText : numToString(stats.reach)"
                ></stat-card>
            </b-td>
            <b-td>
                <stat-card
                    icon='fa-eye'
                    label='Engagement'
                    :value="numToString(stats.engage)"
                ></stat-card>
            </b-td>
            <b-td>
                <stat-card
                    icon='fa-mouse-pointer'
                    label='Clicks'
                    :value="numToString(stats.clicks)"
                ></stat-card>
            </b-td>
            <b-td>
                <stat-card
                    icon='fa-handshake-o'
                    label='Leads to Advisors'
                    :value="numToString(stats.leads)"
                ></stat-card>
            </b-td>
            <b-td>
                <stat-card
                    icon='fa-dollar'
                    label='Investment'
                    :value="`$ ${numToString(totalInvestment) || '-'}`"
                ></stat-card>
            </b-td>
        </b-tr>
        <b-tr>
            <b-td class="cell">{{ perPostTradeCost }}</b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">
                {{ numToCurrency(tradeInvest) }}
            </b-td>
        </b-tr>
        <b-tr>
            <b-td class="cell"></b-td>
            <b-td class="cell">{{ perPostReachCost }}</b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">
                {{ isLinkedIn ? NAText : numToCurrency(reachInvest) }}
            </b-td>
        </b-tr>
        <b-tr>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">{{ perPostEngageCost }}</b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">
                {{ numToCurrency(engageInvest) }}
            </b-td>
        </b-tr>
        <b-tr>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">{{ perPostClickCost }}</b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">
                {{ numToCurrency(clickInvest) }}
            </b-td>
        </b-tr>
        <b-tr>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell"></b-td>
            <b-td class="cell">{{ perPostSalesCost }}</b-td>
            <b-td class="cell">
                {{ numToCurrency(salesInvest) }}
            </b-td>
        </b-tr>
        </b-tbody>
        <b-tfoot>
        <b-tr>
            <b-td colspan="6" variant="secondary" class="text-center" v-if="footerText">
                {{ footerText }}
            </b-td>
        </b-tr>
        </b-tfoot>
    </b-table-simple>
</template>
<script>
    import { numToString } from '@/utils/numbers';
    import StatCard from './StatCard.vue';

    export default {
        name: 'SupplierBudget',
        components: { StatCard },
        props: {
            applicationSlug: {
                type: String,
                required: true,
            },
            countries: {
                type: Array,
                required: true,
            },
            stats: {
                /** { count, agents, reach, engage, clicks, leads } */
                type: Object,
                required: true,
            },
            supplierApplicationBudget: {
                type: Object,
                required: false
            },
        },
        data: function () {
            return {
                clickInvest: 0,
                engageInvest: 0,
                reachInvest: 0,
                salesInvest: 0,
                tradeInvest: 0,
                NAText: 'N/A',
            }
        },
        methods: {
            initInvestments(){
                const {
                    percent_clicks,
                    percent_engagement,
                    percent_reach,
                    percent_sales,
                    percent_trade,
                } = this.budget;
                const investment = this.totalInvestment;
                this.clickInvest = percent_clicks ? investment*Number(percent_clicks)/100 : 0;
                this.engageInvest = percent_engagement ? investment*Number(percent_engagement)/100 : 0;
                this.reachInvest = this.isLinkedIn ? this.NAText : percent_reach ? investment*Number(percent_reach)/100 : 0;
                this.salesInvest = percent_sales ? investment*Number(percent_sales)/100 : 0;
                this.tradeInvest = percent_trade ? investment*Number(percent_trade)/100 : 0;
            },
            getItemCostString(investment, totalCount) {
                return this.totalInvestment && totalCount ? this.numToCurrency(investment/totalCount) : '-';
            },
            numToString,
            numToCurrency(value) {
                const numString = numToString(value);
                return numString ? `$ ${numString}` : '';
            }
        },
        computed: {
            agentCount() {
                return this.stats.count;
            },
            budget() {
                if (this.supplierApplicationBudget && Object.entries(this.supplierApplicationBudget).length) {
                    return this.supplierApplicationBudget;
                } else {
                    return {
                        application_slug: this.applicationSlug,
                        cost_per_post: 0,
                        percent_clicks: 0,
                        percent_engagement: 0,
                        percent_reach: 0,
                        percent_sales: 0,
                        percent_trade: 0,
                        supplier_name: '',
                        notFound: this.supplierApplicationBudget && !Object.entries(this.supplierApplicationBudget).length,
                    }
                }
            },
            campaignTypeIcon() {
                return `fa-${this.applicationSlug}-square`;
            },
            footerText() {
                let text = '';
                if (this.budget) {
                    const { per_booking_amount, leads_into_bookings } = this.budget;
                    if (per_booking_amount > 0 && leads_into_bookings > 0) {
                        if (this.stats && this.stats.leads) {
                            const leads = this.stats.leads;
                            const bookingCount = leads/100*leads_into_bookings;
                            text = `${leads_into_bookings}% of leads (${numToString(bookingCount)}) ` +
                                `become bookings @ ${this.numToCurrency(per_booking_amount)} per booking = ` +
                                this.numToCurrency(bookingCount*per_booking_amount);
                        }
                    }
                }
                return text;
            },
            isLinkedIn(){
                return this.applicationSlug === 'linkedin';
            },
            perPostClickCost() {
                return this.getItemCostString(this.clickInvest, this.stats.clicks);
            },
            perPostEngageCost() {
                return this.getItemCostString(this.engageInvest, this.stats.engage);
            },
            perPostReachCost() {
                if (this.isLinkedIn) {
                    return this.NAText;
                } else {
                    return this.getItemCostString(this.reachInvest, this.stats.reach);
                }
            },
            perPostSalesCost() {
                return this.getItemCostString(this.salesInvest, this.stats.leads);
            },
            perPostTradeCost() {
                return this.getItemCostString(this.tradeInvest, this.agentCount);
            },
            title() {
                if (this.budget.notFound) {
                    return `No budget found for this supplier`;
                }
                const supplierName = this.budget.supplier_name || 'No supplier selected'
                let countryDescription = this.countries.join('/');
                if (this.countries.length > 1) {
                    countryDescription += ' combined';
                }
                return `${supplierName} - ${countryDescription}`;
            },
            totalInvestment() {
                return this.budget ? this.stats.count*this.budget.cost_per_post : 0
            },
        },
        mounted() {
            this.initInvestments();
        }
    }
</script>

<style scoped>
    .header {
        border-right: 1px solid #dee2e6;
        text-align: center;
    }
    .cell {
        text-align: center;
        vertical-align: middle;
        color:rgb(29, 163, 73);
        font-size: 1.5em;
        font-weight: bold;
    }
    .input {
        text-align: right;
        color:rgb(29, 163, 73);
        font-size: 1.5em;
        font-weight: bold;
        max-width: 110px;
    }
</style>
