<template>
    <div class="card mb-3">
        <div class="card-body">
            <section class="pb-4">
                <div class="d-flex">
                    <span class="user-profile__avatar user-profile__avatar--lg">
                        <img class="user-profile__avatar user-profile__avatar--lg" :src="profile.avatar"
                            :alt="profile.first_name + profile.last_name" />
                        <a :href="planPage">
                            <plan-badge :plan="profile.plan" :class="billingBadgeClass" size="lg">
                            </plan-badge>
                        </a>
                    </span>
                    <div class="flex-grow-1 ml-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <h6 class="mb-0">{{ profile.first_name }} {{ profile.last_name }}</h6>
                            <a v-if="profile.profiles_count > 1" data-toggle="modal" data-target="#profile-full-list"
                                href="javascript:void(0);">
                                <i class="d-inline-block d-xl-none fa fa-exchange"></i>
                                <small class="d-none d-xl-inline-block text-right">{{
                                    $t("profile.switch")
                                    }}</small>
                            </a>
                        </div>
                        <p class="mb-0">
                            <small>{{ $t("common.memberSince") }} {{ profile.created }}</small>
                        </p>
                        <p class="mb-0 mt-0 d-flex justify-content-between">
                            <upgrade-to-premium-link></upgrade-to-premium-link>
                            <a v-if="profile.plan === 'free'" href="javascript:void(0);"
                                @click="showVideoModal('premium-mkt-modal')" class="d-flex align-items-center">
                                <i class="fa fa-youtube-play text-dark" aria-hidden="true"></i>
                                <small class="ml-1 reset-link">Learn more</small>
                            </a>
                            <a v-if="profile.plan === 'pro'" href="javascript:void(0);"
                                @click="showVideoModal('premium-setup-modal')" class="reset-link text-right">
                                <i class="fa fa-youtube-play text-dark" aria-hidden="true"></i>
                                <small class="ml-1">How-to setup your PRO membership</small>
                            </a>
                        </p>

                        <b-modal v-if="profile.plan === 'free'" body-class="no-padding" ref="premium-mkt-modal"
                            title="BranchUp PRO Membership" size="xl" hide-footer hide-header centered>
                            <b-embed type="iframe" aspect="16by9" :src="billing_mkt_video_url" allowfullscreen>
                            </b-embed>
                            <a @click="hideVideoModal('premium-mkt-modal')" class="modal-floating-close"
                                href="javascript:void(0);">
                                <i class="fa fa-times fa-lg align-middle" aria-hidden="true"></i>
                            </a>
                        </b-modal>

                        <b-modal v-if="profile.plan === 'pro'" body-class="no-padding" ref="premium-setup-modal"
                            title="How to setup BranchUp PRO membership" size="xl" hide-footer hide-header centered>
                            <b-embed type="iframe" aspect="16by9" :src="billing_setup_video_url" allowfullscreen>
                            </b-embed>
                            <a @click="hideVideoModal('premium-setup-modal')" class="modal-floating-close"
                                href="javascript:void(0);">
                                <i class="fa fa-times fa-lg align-middle" aria-hidden="true"></i>
                            </a>
                        </b-modal>
                    </div>
                </div>
            </section>
            <section class="border-top pt-2 pt-lg-3">
                <a href="javascript:void(0);" class="small text-uppercase text-center d-block d-lg-none"
                    v-b-toggle.collapse-profile-steps>
                    <span class="view-more">
                        <i class="fa fa-chevron-down"></i>
                        <span class="view-more-label text-muted">{{ $t("common.viewMore") }}</span>
                    </span>
                </a>
                <b-collapse id="collapse-profile-steps" class="d-lg-block profile-steps">
                    <ul class="list-unstyled mb-0">
                        <li class="d-flex justify-content-between mb-2">
                            <span>{{ $t("common.profileComplete") }}</span>
                            <i :class="profile.completed ? 'text-success' : 'text-muted'" class="fa fa-check">
                            </i>
                        </li>
                        <li class="d-flex justify-content-between mb-2">
                            <span>{{ $t("common.emailVerified") }}</span>
                            <i :class="profile.email_verification ? 'text-success' : 'text-muted'"
                                class="fa fa-check text-success">
                            </i>
                        </li>
                        <li class="d-flex justify-content-between">
                            <span>{{ $t("profile.platformConnected") }}</span>
                            <i :class="profile.applications &&
                                    Object.values(profile.applications).some((app) => app.active)
                                    ? 'text-success'
                                    : 'text-muted'
                                " class="fa fa-check">
                            </i>
                        </li>
                        <li class="d-flex justify-content-between mt-2"
                            v-if="profile.plan == 'pro' && profile.applications.generator.visible">
                            <span>
                                {{ $t("profile.generatorConnected") }}
                                <plan-badge plan="pro" class="ml-1"></plan-badge>
                            </span>
                            <template v-if="profile.applications.generator.is_enabled">
                                <i class="fa fa-check text-success"></i>
                            </template>
                            <template v-else>
                                <a class="reset-link" :href="aiPreferencesPage">
                                    <small class="d-flex text-right">{{
                                        $t("connection.itemLabelNotSetup")
                                        }}</small>
                                </a>
                            </template>
                        </li>
                    </ul>
                </b-collapse>
            </section>
        </div>
    </div>
</template>

<script>
import { routes, views } from "@/components/common/views-config.js";
import PlanBadge from "@/components/common/PlanBadge.vue";
import UpgradeToPremiumLink from "@/components/plans/UpgradeToPremiumLink.vue";

const profile = window.profileData;

export default {
    name: "ProfileWidget",
    data: function () {
        return {
            profile: profile,
            routes: routes,
            billing_setup_video_url: window.billing_setup_video_url,
            billing_mkt_video_url: window.billing_mkt_video_url,
            planPage: views.profile_plan(profile.slug),
            aiPreferencesPage: views.ai_preferences(profile.slug),
        };
    },
    components: {
        PlanBadge,
        UpgradeToPremiumLink,
    },
    methods: {
        showVideoModal(target) {
            this.$refs[target].show();
        },
        hideVideoModal(target) {
            this.$refs[target].hide();
        },
    },
    computed: {
        billingBadgeClass() {
            return `user-profile__badge user-profile__badge--${this.profile.plan} user-profile__badge--lower`;
        },
    },
};
</script>

<style lang="scss" scoped>
.view-more-label {
    font-size: 10px;
    margin-left: 5px;
}

.not-collapsed {
    .view-more {
        display: none;
    }

    +.profile-steps {
        margin-top: 0.5rem;
    }
}
</style>
