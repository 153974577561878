<template>
    <item-list
        ref="list"
        :endpoint="endpoint"
        :itemComponent="PostEmarketing"
        :emptyComponent="emptyComponent"
        :emptyText="emptyText"
    ></item-list>
</template>

<script>
    import { shallowRef } from 'vue';
    import { endpoints } from '@/components/common/api-config.js';
    import ItemList from '@/components/common/ItemList.vue';
    import PostEmarketing from '@/components/post/PostEmarketing.vue';
    import EmptyPost from '@/components/post/EmptyPost.vue';

    const VALID_QUERIES = ['upcoming', 'published', 'deleted', 'top']

    export default {
        name: 'emarketing-post-list',
        components: {
            ItemList,
        },
        props: {
            emarketingId: {
                type: String,
                required: true
            },
            dateFrom: {
                type: Date,
                required: false
            },
            dateUntil: {
                type: Date,
                required: false
            },
            queryType: {
                type: String,
                required: false,
                validator: function(value) {
                    return VALID_QUERIES.includes(value);
                }
            },
        },
        data() {
            return {
                EmptyPost: shallowRef(EmptyPost),
                PostEmarketing: shallowRef(PostEmarketing)
            };
        },
        computed: {
            endpoint() {
                return endpoints['scheduledemailcampaign-list'](
                    this.emarketingId,
                    this.dateFrom,
                    this.dateUntil,
                    this.queryType
                );
            },
            emptyComponent() {
                return !this.queryType || this.queryType === 'upcoming' ? EmptyPost : null;
            },
            emptyText() {
                let text = null;
                if (this.queryType && this.queryType !== 'upcoming') {
                    switch (this.queryType) {
                        case 'published':
                            text = 'There are no published emails in this time frame.';
                            break;
                        case 'deleted':
                            text = 'There are no deleted emails yet.';
                            break;
                        case 'top':
                            text = 'No data available';
                            break;
                        default:
                            break;
                    }
                }
                return text;
            }
        },
    }
</script>
