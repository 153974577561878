<template>
    <article class="card card--shadow-border mb-4 socialpost">
        <section class="card-body socialpost--body">
            <div class="socialpost--header socialpost--header__instagram">
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <span class="align-self-center mr-2">
                            <img
                                class="socialpost--image"
                                :src="profile.applications.instagram.data.image"
                                :alt="profile.first_name + profile.last_name">
                        </span>
                        <div class="socialpost--title">
                            <div class="socialpost--name">{{ profile.applications.instagram.data.name }}</div>
                        </div>
                    </div>
                    <div class="socialpost--actions text-muted align-self-center mr-1">
                        <i class="fa fa-lg fa-ellipsis-h" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </section>
        <main class="card-footer card-footer--no-padding card-footer--no-link">
            <div
                v-if="post.image"
                class="overflow-hidden rounded-bottom">
                <img
                    v-if="!post.link && !post.external_link"
                    v-lazy="{ src: post.image }"
                    alt=""
                    class="img-fluid">
            </div>
        </main>
        <section class="card-footer socialpost--footer">

            <div class="d-flex justify-content-between mb-3 socialpost--icons__instagram">
                <div>
                    <span class="mr-3">
                        <i class="fa fa-heart-o fa-lg"></i> 190
                    </span>
                    <span class="mr-3">
                        <i class="fa fa-comment-o fa-lg"></i> 5
                    </span>
                    <span class="mr-2">
                        <i class="fa fa-paper-plane-o fa-lg"></i>
                    </span>
                </div>
                <span>
                    <i class="fa fa-bookmark-o fa-lg"></i>
                </span>
            </div>

            <p class="text-breakspaces mb-0 text-dark socialpost--message" v-html="postMessage"></p>
        </section>
    </article>
</template>

<script>
    import { markHashTags } from '@/utils/hashtags';

    export default {
        name: 'PostPreviewInstagram',
        props: {
            post: {
                type: Object,
                required: true,
            }
        },
        data(){
            return {
                profile: window.profileData,
            }
        },
        computed: {
            postMessage(){
                return markHashTags(this.post.message, true, 'instagram', 'socialpost--message--hashtag__instagram');
            },
        },
    }
</script>
