<template>
    <div class="row">
        <div class="col-12">
            <h5 class="title mb-4 d-flex justify-content-between justify-content-lg-start">
                Calendar
                <b-button v-if="isBeta" :href="`/${profile.slug}/new_post/`" variant="primary" class="ml-auto">
                    <i class="fa fa-plus"></i> New Post
                </b-button>
            </h5>

            <app-multi-select v-model:value="selectedApps" />
            <bu-calendar :selected-apps="selectedApps" />
        </div>
    </div>
</template>

<script>
    import AppMultiSelect from '@/components/calendar/AppMultiSelect.vue';
    import BUCalendar from '@/components/calendar/BUCalendar.vue';

    export default {
        name: 'CalendarDashboard',
        components: {
            AppMultiSelect,
            'bu-calendar': BUCalendar,
        },
        data() {
            return {
                profile: window.profileData,
                selectedApps: [],
                isBeta: window.isBeta,
            }
        },
    }
</script>
