<template>
    <div class="mb-4">
        <article class="card card--shadow-border mb-4">
            <section class="card-body card-body--no-padding">
                <section class="d-flex justify-content-between p-3">
                    <div>
                        <div class="d-flex">
                            <div v-if="post.logo" class="align-self-center">
                                <img :src="post.logo" height="48" alt="">
                            </div>
                            <div>
                                <h3 class="h5 mb-0 mt-1" v-if="pageName">{{ pageName }}</h3>
                                <p class="small mb-0">
                                    <span v-if="post.status == 'pending'">
                                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        {{ $t("common.scheduled") }}
                                    </span>
                                    <span v-if="post.status == 'published'">
                                        <i class="fa fa-check"  aria-hidden="true"></i>
                                        {{ $t("common.delivered") }}
                                    </span>
                                    {{ fireAtDateString }}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div
                    v-if="post.image_thumbnail"
                    class="overflow-hidden">
                    <img
                        v-lazy="{ src: post.image_thumbnail, loading: post.image_placeholder }"
                        alt=""
                        class="w-100">
                </div>
                <div class="p-3">
                    <p class="mb-2">
                        <strong>{{ $t("common.campaign") }}:</strong>
                        {{ post.name }}
                    </p>
                    <p v-if="post.editable" class="mb-0">
                        <strong>{{ $t("common.editableUntil") }}:</strong>
                        {{ editByDateString }}
                    </p>
                </div>
                <div v-if="post.num_sent || post.num_open || post.num_click" class="p-3 border-top">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h4 class="h6 mb-0">{{ post.num_sent }}</h4>
                            <p class="mb-0 small">{{ $t("common.sent") }}</p>
                        </div>
                        <div>
                            <h4 class="h6 mb-0">{{ post.num_open }}</h4>
                            <p class="mb-0 small">{{ $t("common.opens") }}</p>
                        </div>
                        <div>
                            <h4 class="h6 mb-0">{{ post.num_click }}</h4>
                            <p class="mb-0 small">{{ $t("common.clicks") }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <main class="card-footer card-footer--no-padding card-footer--no-border">
                <p v-if="post.stats.length" class="p-3 mb-0 text-center">
                    <a :href="postReportURL" class="btn-link">{{ $t("common.detailedMetrics") }}</a>
                </p>
                <p v-else class="p-3 mb-0 text-center">
                    <a :href="postDetailURL" class="btn-link">{{ $t("common.previewCampaign") }}</a>
                </p>
            </main>
        </article>
    </div>
</template>

<script>
    import { formatDate } from '@/utils/datetime.js';
    import { views } from '@/components/common/views-config.js';

    export default {
        name: 'PostEmarketing',
        props: {
            pageName: {
                type: String,
                required: false
            },
            item: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                post: this.$props.item
            };
        },
        computed: {
            editByDateString(){ return formatDate.long(this.$props.item.edit_by); },
            fireAtDateString(){ return formatDate.long(this.$props.item.fire_at); },
            postDetailURL(){
                return views.emarketing_scheduled_email_campaign(
                    this.$props.item.id, window.profileData.slug
                );
            },
            postReportURL(){
                return views.emarketing_reports_detail(this.$props.item.id, window.profileData.slug);
            },
        }
    }
</script>
