<template>
    <confirm-and-wait-modal
        okTitle="Edit"
        title="Edit post"
        cancel-variant="light"
        method="patch"
        :params="form">
        <div>
            <img
                v-lazy="{ src: post.data.image.image_small, loading: post.data.image.image_thumb }"
                alt=""
                class="img-fluid mb-2">
            <b-form>
                <b-form-group>
                    <b-form-textarea
                        id="edit-message"
                        required
                        v-model="form.message"
                        rows="7"
                    ></b-form-textarea>
                </b-form-group>
            </b-form>
        </div>

    </confirm-and-wait-modal>
</template>

<script>
import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';
export default {
  components: { ConfirmAndWaitModal },
    name: 'GeneratedPostEditModal',
    props: {
        post:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            form: {'message': this.$props.post.message}
        }
    },
};
</script>
