<template>
    <confirm-and-wait-modal
        :endpoint="`/api/scheduled_postings/${post.id}/delete/`"
        :id="`postDelete${post.id}`"
        :params="this.params"
        :title="this.$t('post.actions.deletePost')"
        method="post">
        <p>{{ $t("post.actions.deletePostQuestion") }}</p>
        <div v-if="profileCount > 1" class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="post-delete-apply-to-all"
                v-model="params.apply_to_all">
            <label class="form-check-label" for="post-delete-apply-to-all">
                {{ $t("post.actions.applyAll") }}
            </label>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import ConfirmAndWaitModal from '../common/ConfirmAndWaitModal.vue';

    export default {
        name: 'PostActionsDelete',
        components: {
            ConfirmAndWaitModal
        },
        props: {
            post: {
                type: Object,
                required: true
            },
            profileCount: {
                type: Number,
                required: true
            }
        },
        data: function () {
            return {
                params: {
                    apply_to_all: false
                }
            };
        }
    }
</script>
