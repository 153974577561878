<template>
    <div class="row">
        <div class="col-md-3">
            <b-form-group label="Search:" label-for="filter-input">
                <b-form-input
                    id="filter-input"
                    v-model="filters.search"
                    placeholder="Enter keyword to filter posts"
                    debounce="500"
                ></b-form-input>
            </b-form-group>

            <endpoint-fetcher
                :endpoint="campaignLibraryEndpoint"
                v-model:fetchedData="collections"
                :processResponse="response => response.data.results"
            >
                <template v-if="collections && collections.length">
                    <div class="custom-filters">
                        <b-form-group label="Filter by Collection:">
                            <b-form-checkbox
                                v-for="collection in collections"
                                :key="collection.id"
                                v-model="filters.collections[collection.id]"
                                class="mt-2"
                            >
                                {{ collection.title }}
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </template>
            </endpoint-fetcher>
        </div>
        <div class="col-md-9">
            <h4>Choose from Social Library</h4>
            <post-list-selector
                v-model:value="selectedPost"
                :endpoint="postLibraryEndpoint"
                itemWrapperClass="row"
                itemClass="col-md-4"
                paginatorClass="col-12">
            </post-list-selector>
        </div>
    </div>

</template>
<script>
    import {endpoints} from '@/components/common/api-config.js';
    import PostListSelector from './PostListSelector.vue';
    import EndpointFetcher from '@/components/common/EndpointFetcher.vue';

    export default {
        name: 'PostLibrary',
        emits: ['update:value'],
        props: {
            value: {
                type: Object,
                required: false
            },
        },
        components: {
            EndpointFetcher,
            PostListSelector,
        },
        data() {
            return {
                profile: window.profileData,
                filters: {
                    search: null,
                    collections: {},
                },
                selectedPost: this.$props.value,
                collections: [],
            }
        },
        computed: {
            postLibraryEndpoint() {
                const selectedCollections = Object.keys(this.filters.collections).filter(
                    (key) => this.filters.collections[key] === true
                );
                return endpoints['post-library'](this.profile.pk, this.filters.search, selectedCollections);
            },
            campaignLibraryEndpoint() {
                return endpoints['campaign-library'](this.profile.pk);
            },
        },
        watch: {
            selectedPost(newValue) {
                this.$emit('update:value', newValue);
            }
        },
    }
</script>
