<template>
    <b-modal
        :id="modalId"
        v-if="(applicationState.installed == true) && (applicationState.connected == false)"
        :title="`${connectedAppConfig.name} Not Connected`"
        centered
    >
        <connection-broken :app="app"></connection-broken>
        <p class="text-center mb-3">
            {{ $t('connection.broken.desc') }}
        </p>
        <template #modal-footer>
            <b-button variant="link" :href="removeAppURL">
                {{ $t('common.remove') }} {{ connectedAppConfig.name }}
            </b-button>
            <b-button variant="primary" :href="reconnectURL">
                {{ $t('common.reconnectNow') }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import ConnectionBroken from './ConnectionBroken.vue';
    import { connectionConfigValues } from './apps-connection-config.js';
    import { routes } from './common/views-config.js';
    import { useMainStore } from '@/main-store';

    export default {
        name: 'AppsDisconnectedModal',
        components: {
            ConnectionBroken
        },
        props: {
            app: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false,
            }
        },
        data: function () {
            return {
                mainStore: useMainStore(),
                connectedAppConfig: connectionConfigValues[this.$props.app],
            };
        },
        computed: {
            profile() {
                return window.profile || {};
            },
            applicationState() {
                return this.mainStore[this.$props.app];
            },
            removeAppURL() {
                return routes.removeApp(this.profile.slug, this.$props.app);
            },
            reconnectURL() {
                return routes.connect(this.profile.slug, this.$props.app);
            },
            modalId() {
                return this.id || `${this.app}-disconnected-modal`;
            }
        }
    }
</script>
