<template>
    <b-modal :id="modalId" centered>
        <template #modal-header>
            <h5 class="modal-title" id="app-status-modal-label">
                <i class="fa fa-info-circle mr-2"></i> Additional information for {{ appName }}
            </h5>
            <button @click="closeModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </template>
        <div v-html="messageBody"></div>
        <template #modal-footer>
            <b-button variant="primary" @click="closeModal">OK</b-button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: 'ConnectionMessageModal',
        props: {
            modalId: {
                type: String,
                required: true
            },
            appName: {
                type: String,
                required: true
            },
            messageBody: {
                type: String,
                required: true
            },
        },
        methods: {
            closeModal() {
                this.$bvModal.hide(this.modalId);
            }
        }
    };
</script>