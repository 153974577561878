<template>
    <slot>
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between align-items-center">
                    <h4 class="mb-0">Billing Address</h4>
                    <b-button
                        variant="outline-primary"
                        v-b-modal.billing-edit-modal
                        class="ms-3"
                    >
                        <i class="fa fa-pencil"></i> Edit
                    </b-button>
                </div>
                <p class="text-muted">
                    <i class="fa fa-info-circle"></i>
                    Your billing address should match your credit card billing address.
                </p>
                <dl class="row">
                    <dt class="col-3">Address</dt>
                    <dd class="col-9">{{ billingData.address }}</dd>

                    <dt class="col-3">City</dt>
                    <dd class="col-9">{{ billingData.city }}</dd>

                    <dt class="col-3">State / Province</dt>
                    <dd class="col-9">{{ billingData.state }}</dd>

                    <dt class="col-3">Zip / Postal code</dt>
                    <dd class="col-9">{{ billingData.zip_code }}</dd>

                    <dt class="col-3">Country</dt>
                    <dd
                        class="col-9"
                        v-if="billingData.country in addressOptions"
                    >
                        {{ addressOptions[billingData.country].label }}
                    </dd>
                </dl>
            </div>
        </div>
    </slot>

    <endpoint-fetcher
        class="d-none"
        :endpoint="billingAddressOptionsEndpoint"
        v-model:fetchedData="addressOptions"
        :show-loading="false"
    ></endpoint-fetcher>

    <endpoint-fetcher
        v-if="!billingData"
        class="d-none"
        :endpoint="billingRetrieveEndpoint"
        v-model:fetchedData="billingData"
        :show-loading="false"
    ></endpoint-fetcher>

    <confirm-and-wait-modal
        id="billing-edit-modal"
        :endpoint="billingUpdateEndpoint"
        :okTitle="submitLabel"
        :params="form"
        :successMessage="Saved"
        title="Billing Address"
        :method="billingEndpointMethod"
        :okCallback="updateBillingData"
    >
        <div>
            <p class="text-muted">
                <i class="fa fa-info-circle"></i>
                Your billing address should match your credit card billing address.
            </p>
            <b-form>
                <b-form-group>
                    <v-select
                        id="billing-country"
                        placeholder="Country"
                        :options="countryOptions"
                        :reduce="(country) => country.code"
                        :clearable="false"
                        :required="true"
                        v-model="form.country"
                    >
                    </v-select>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="billing-address"
                        placeholder="Billing address"
                        required
                        v-model="form.address"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="billing-address"
                        placeholder="Billing city"
                        required
                        v-model="form.city"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <v-select
                        id="billing-state"
                        placeholder="State / Province"
                        :options="statesOptions"
                        :reduce="(state) => state.code"
                        :clearable="false"
                        :required="true"
                        v-model="form.state"
                    >
                    </v-select>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        id="billing-zip-code"
                        placeholder="Billing zip code"
                        required
                        v-model="form.zip_code"
                    ></b-form-input>
                </b-form-group>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import { endpoints } from "@/components/common/api-config.js";
    import { useMainStore } from "@/main-store";
    import vSelect from "vue-select";
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";

    export default {
        name: "BillingAddress",
        components: { EndpointFetcher, ConfirmAndWaitModal, vSelect },
        emits: ["update:value", "update"],
        props: {
            value: {
                // A BillingData object is expected here with all the serialized data given by the billing API
                type: Object,
                default: null,
            },
            submitLabel: {
                type: String,
                default: "Save",
            },
        },
        data: function () {
            return {
                mainStore: useMainStore(),
                billingAddressOptionsEndpoint: endpoints["billing-address-options"],
                addressOptions: {},
                billingData: this.$props.value,
                form: {
                    user: "",
                    address: "",
                    city: "",
                    state: "",
                    zip_code: "",
                    country: "",
                },
            };
        },
        computed: {
            billingRetrieveEndpoint() {
                return endpoints["billing"](this.mainStore.profile.user_pk);
            },
            billingUpdateEndpoint() {
                if (this.billingData?.plan?.uid) {
                    return endpoints["billing"](this.mainStore.profile.user_pk);
                } else {
                    return endpoints["billing-create"];
                }
            },
            billingEndpointMethod() {
                return this.billingData?.plan?.uid ? "put" : "post";
            },
            countryOptions() {
                return Object.keys(this.addressOptions).map((countryCode) => {
                    const countryData = this.addressOptions[countryCode];
                    return {
                        label: countryData.label,
                        code: parseInt(countryCode),
                    };
                });
            },
            statesOptions() {
                if (!this.form.country) return [];
                return this.addressOptions[this.form.country].states;
            },
        },
        watch: {
            value(newBillingData) {
                // make a copy of the billingData for user to edit
                this.billingData = newBillingData;
                this.form = {
                    user: this.mainStore.profile.user_pk,
                    address: this.billingData.address || this.mainStore.profile.address,
                    city: this.billingData.city || this.mainStore.profile.city,
                    state: this.billingData.state || this.mainStore.profile.state,
                    zip_code: this.billingData.zip_code || this.mainStore.profile.zip_code,
                    country: this.billingData.country || parseInt(this.mainStore.profile.country_pk),
                };
            },
            "form.country"(newCountry) {
                const newCountryStates = this.addressOptions[newCountry]?.states || [];
                const currentStateExists = newCountryStates.some((state) => state.code === this.form.state);
                if (!currentStateExists && newCountryStates.length > 0) {
                    this.form.state = null; // Reset the state if it doesn't exist in the new country
                }
            },
        },
        methods: {
            updateBillingData(response) {
                this.billingData = response.data;
                this.$emit("update:value", this.billingData);
                // emit custom event to inform specific this component change
                this.$emit("update", this.billingData);
            },
        },
    };
</script>
