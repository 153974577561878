<template>
    <small>
        <b class="mr-2">{{ $t('post.filterAddedDate') }}</b>
    </small>
    <b-dropdown
        :text="period_selected_label"
        id="created-period"
        right
        variant="outline-dark">
        <b-dropdown-item-button v-for="(item, index) in period_options" v-bind:key="item.id"
            @click="filterByCreated(index)">
            {{ item.label }}
        </b-dropdown-item-button>
    </b-dropdown>
</template>
<script>
    import { setDate } from '@/utils/datetime';

    export default {
        name: 'PostItemsPeriodsCreated',
        emits: ['postPeriodUpdate'],
        data() {
            return {
                period_options: [
                    {
                        id: 'filter-created-select',
                        week: null,
                        fromDate: '',
                        toDate: '',
                        label: "----"
                    }
                ],
                period_selected: 0,
                period_selected_label: '',
            }
        },
        methods: {
            populate_period(){
                const numWeeks = 8;
                const human_format = 'ddd MMM D';

                this.period_selected = 0;
                this.period_selected_label = this.period_options[0].label;

                for (let i = 0; i < numWeeks; i++) {
                    let start = setDate.now().add(i * -7, 'days');
                    let monday = start.startOf('isoWeek');
                    let sunday = monday.clone().add(6, 'days');
                    let week = {
                        id: `${i}-week`,
                        fromDate: monday,
                        toDate: sunday,
                        label: monday.format(human_format) + ' - ' + sunday.format(human_format),
                    }
                    this.period_options.push(week);
                }
            },
            selectPeriod(period){
                this.period_selected = period;
                this.period_selected_label = this.period_options[period].label;
            },
            filterByCreated(period) {
                this.selectPeriod(period);
                this.$emit('postPeriodUpdate', this.period_options[period]);
            },
            selectNone() {
                this.selectPeriod(0);
            },
        },
        beforeMount() {
            this.populate_period();
        }
    }
</script>
