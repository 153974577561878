<template>
    <div class="row announcements-widget" v-if="displayedAnnouncement" :key="displayedAnnouncement.id">
        <div class="col-12 text-center">
            <div :class="['alert', 'alert-' + displayedAnnouncement.type]" role="alert">
                <a v-if="displayedAnnouncement.link" :href="displayedAnnouncement.link" target="_blank" v-html="displayedAnnouncement.message"></a>
                <span v-else v-html="displayedAnnouncement.message"></span>
                <button type="button" class="close ml-4" data-dismiss="alert" aria-label="Close" @click="dismissAnnouncement()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from '../../setup/axios-setup';
    import { endpoints } from '../common/api-config.js';
    import Toast from '../../toast.js';
    const toast = new Toast();
    const profile = window.profileData;

    export default {
        name: 'AnnouncementsWidget',
        data() {
            return {
                announcements: [],
                displayedAnnouncement: null,
            };
        },
        methods: {
            getAnnouncements() {
                axios.get(
                    endpoints.announcements_list(
                        profile.pk
                    ))
                    .then((response) => {
                        this.announcements = response.data.results;

                        if(this.announcements.length > 0) {
                            this.displayedAnnouncement = this.announcements.shift();
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response.data.error, "Error");
                    })
            },
            dismissAnnouncement() {
                this.displayedAnnouncement = this.announcements.shift();
            }
        },
        mounted() {
            this.getAnnouncements();
        }
    }
</script>

<style scoped>
    @media (min-width: 768px) {
        .announcements-widget .alert {
            margin-bottom: 0;
        }
    }
    @media (max-width: 767px) {
        .announcements-widget .alert {
            margin-top: -15px;
        }
    }
</style>
