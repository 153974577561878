<template>
    <b-tabs>
        <b-tab
            :title="isPaused ? pausedTitle : $t('common.upcoming')"
            @click="selectTab(tabs.UPCOMING)"
        ></b-tab>
        <b-tab
            :title="$t('common.published')"
            @click="selectTab(tabs.PUBLISHED)"
        ></b-tab>
        <b-tab
            :title="$t('common.deleted')"
            @click="selectTab(tabs.DELETED)"
        ></b-tab>
    </b-tabs>
</template>
<script>
    import {tabs} from '@/components/common/scheduled-tabs-config.js';

    export default {
        name: 'ScheduledTabs',
        props: {
            isPaused: {
                type: Boolean,
                required: true,
            },
        },
        emits: ['select'],
        data() {
            return {
                currentTab: 'upcoming',
                tabs,
            }
        },
        methods: {
            selectTab(tabName) {
                this.currentTab = tabName;
                this.$emit('select', tabName);
            },
        },
        computed: {
            pausedTitle() {
                return this.$createElement('span', [
                    this.$createElement('i', { class: 'fa fa-pause' }),
                    ` ${this.$t('common.paused')}`
                ]);
            },
        }
    }
</script>
