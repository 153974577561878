<template>
    <div class="d-flex align-items-center">
        <small v-if="!this.hideLabel">
            <b class="mr-2">{{ $t("post.filterPostingDate") }}</b>
        </small>
        <b-dropdown
            :text="$t(`common.${periodSelectedLabel}`)"
            right
            variant="outline-dark">
            <b-dropdown-item-button
                v-for="(item, index) in filteredPeriodOptions" v-bind:key="item.id"
                @click="selectPeriod(index)">
                {{ $t(`common.${item.label}`) }}
            </b-dropdown-item-button>
        </b-dropdown>
    </div>
</template>

<script>
    import { futurePeriods, pastPeriods } from '@/components/DashboardConfig';
    import { setDate } from '@/utils/datetime';

    export default {
        name: 'PostItemsPeriods',
        emits: ['postPeriodUpdate'],
        props: {
            upcoming: {
                type: Boolean,
                default: true
            },
            excludeAllPeriod: {
                type: Boolean,
                default: false
            },
            hideLabel: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                dateStart: null,
                dateEnd: null,
                periodOptions: [],
                periodSelected: 0,
                periodSelectedLabel: ''
            };
        },
        methods: {
            setPeriodOptions() {
                this.periodOptions = this.$props.upcoming ? futurePeriods : pastPeriods;
                this.selectPeriod();
            },
            selectPeriod(period=0) {
                this.periodSelected = period;
                this.periodSelectedLabel = this.periodOptions[period].label;
                const timeFrame = this.$props.upcoming ? 'upcoming' : 'published';
                const days = this.periodOptions[period].days
                const dates = this.selectDates(days);
                const updatedValues = [days, period, timeFrame, ...dates]
                this.$emit('postPeriodUpdate', updatedValues);
            },
            selectAll() {
                // last option is all
                this.selectPeriod(this.periodOptions.length - 1)
            },
            selectDates(days) {
                if (days === 0) {
                    this.dateStart = this.$props.upcoming ? setDate.now() : null;
                    this.dateEnd = null;
                } else if (this.$props.upcoming) {
                    this.dateStart = setDate.now();
                    this.dateEnd = setDate.add(days);
                } else {
                    this.dateStart = setDate.subtract(days);
                    this.dateEnd = setDate.now();
                }
                return [this.dateStart, this.dateEnd];
            }
        },
        computed: {
            filteredPeriodOptions() {
                if (this.excludeAllPeriod) {
                    return this.periodOptions.filter(item => item.id !== 'allDates');
                } else {
                    return this.periodOptions;
                }
            }
        },
        watch: {
            reset: 'setPeriodOptions',
            upcoming: 'setPeriodOptions',
        },
        mounted() {
            this.setPeriodOptions();
        }
    }
</script>
