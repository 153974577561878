// These constants should match the ones in the backend at dolos.models.PostingStatus
export const postingStatus = {
    PENDING: 0,
    POSTED: 10,
    REPOSTED: 15,
    POST_ERROR: 20,
    WILL_BE_DELETED: 30,
    DELETED: 40,
    DELETED_BY_FREQUENCY: 45,
    DELETE_ERROR: 50,
    POST_FAILED_1: 100,
    POST_FAILED_2: 110,
    POST_FAILED_3: 120,
};
