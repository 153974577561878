import { createI18n } from 'vue-i18n';
import messages from '@/locales/lang.js';
import axios from './axios-setup';

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  allowHtmlInMessage: true,
  warnHtmlInMessage: 'off',
  messages
});

function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadProfileLang(lang) {
  setI18nLanguage(lang, messages.default);
}
