<template>
    <div>
        <div class="card mb-4">
            <div class="card-body">
                <div class="card-title">
                    <h5>{{ supplier.supplier_label }}</h5>
                </div>
                <div class="clearfix"></div>
                <div class="mb-3">
                    <strong>{{ $t("supplier.SupplierPreference") }}:</strong>
                    {{ supplier.exclude ? $t("supplier.Inactive") : $t("supplier.Active") }}
                </div>
                <div class="clearfix"></div>
                <div class="mb-3">
                    <strong>{{ supplier.cobranded_label }}:</strong> {{ supplier.cobranded_value }}
                </div>
                <div class="clearfix"></div>
                <div class="mb-3">
                    <i
                        :class="supplier.cobranded_disclaimer_date === '' ? 'text-muted' : 'text-success'"
                        class="fa fa-check"
                    ></i>
                    {{ supplier.cobranded_disclaimer }}
                </div>
                <div class="clearfix"></div>
                <div class="mb-3">
                    {{ $t("supplier.Click") }}
                    <a
                        :href="supplier.cobranded_website"
                        target="_blank"
                        >{{ $t("supplier.Here") }}</a
                    >
                    {{ $t("supplier.CobrandedSupplierInstruction") }}
                </div>
                <b-button
                    @click="showModal(`editSupplier${supplier.id}`)"
                    class="btn-bu btn-bu-warning btn-bu-no-icon m-1"
                    variant="warning"
                >
                    {{ $t("supplier.title") }}
                </b-button>
                <cobranded-edit
                    :okCallback="updateParent"
                    :supplier="supplier"
                ></cobranded-edit>
            </div>
        </div>
    </div>
</template>

<script>
    import CobrandedEdit from "./CobrandedEdit.vue";

    export default {
        name: "CobrandedCard",
        emits: ["add-item"],
        components: { CobrandedEdit },
        props: {
            id: {
                type: String,
                default: "cobranded-card",
            },
            item: {
                type: Object,
                required: true,
            },
        },
        data: function () {
            return {
                supplier: this.$props.item,
            };
        },
        methods: {
            updateParent(response) {
                // Emit an add item element to refresh parent
                this.$emit("add-item", response.data);
            },
            showModal(target) {
                this.$bvModal.show(target);
            },
        },
    };
</script>
