<template>
    <b-button
        variant="outline-dark"
        v-b-modal="downgradeModalId"
    >
        Membership Downgrade
    </b-button>
    <confirm-and-wait-modal
        id="downgrade-modal"
        title="Downgrade billing account"
        :endpoint="downgradeUrl"
        method="post"
    >
        <p>Are you sure you want to downgrade the billing account?</p>
        <p>
            This action will disconnect Instagram, remove upcoming reels, and any PRO data enter by the user
            on the profile
        </p>
    </confirm-and-wait-modal>

    <b-modal
        id="no-downgrade-modal"
        title="Downgrade billing account error"
        hide-footer
    >
        <p class="my-4 text-danger text-center">You need to first downgrade it on Outseta.</p>
    </b-modal>
</template>

<script>
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";
    import { endpoints } from "@/components/common/api-config.js";

    export default {
        name: "DowngradeBillingAccount",
        components: {
            ConfirmAndWaitModal,
        },
        props: {
            userId: {
                type: Number,
                required: true,
            },
            planSlug: {
                type: String,
                required: true,
            },
        },
        computed: {
            downgradeModalId() {
                return this.planSlug === "free" ? "downgrade-modal" : "no-downgrade-modal";
            },
            downgradeUrl() {
                return endpoints["superuser-downgrade"](this.userId);
            },
        },
    };
</script>
