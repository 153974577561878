<template>
    <item-list
        ref="list"
        :endpoint="endpoint"
        :item-component="PostListSelectorItem"
        :empty-component="PostListSelectorEmpty"
        @select-item="selectItem"
    ></item-list>
</template>

<script>
    import { shallowRef } from "vue";
    import PostListSelectorItem from "./PostListSelectorItem.vue";
    import PostListSelectorEmpty from "./PostListSelectorEmpty.vue";
    import ItemList from "@/components/common/ItemList.vue";

    export default {
        name: "PostListSelector",
        emits: ["update:value"],
        props: {
            value: {
                type: Object,
                required: false,
            },
        },
        components: {
            ItemList,
        },
        data() {
            return {
                PostListSelectorItem: shallowRef(PostListSelectorItem),
                PostListSelectorEmpty: shallowRef(PostListSelectorEmpty),
                selectedPost: this.$props.value,
            };
        },
        methods: {
            selectItem(item) {
                if (this.selectedPost) {
                    this.selectedPost.selected = false;
                }
                this.$emit("update:value", item);
                this.selectedPost = item;
            },
        },
    };
</script>
