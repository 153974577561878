<template>
    <section class="text-center">
        <h1 class="h2 mb-0">BranchUp Memberships</h1>
        <p class="h5 mt-3 mb-4">Expand your influence and grow your business with PRO Membership</p>
    </section>

    <endpoint-fetcher
        :endpoint="billingEndpoint"
        v-model:fetchedData="billingData"
        :okCallback="billingDataFetched"
    >
        <billing-alert :billingData="billingData"></billing-alert>
        <plan-list
            @plan-click="planButtonClick"
            :billingData="billingData"
        ></plan-list>
    </endpoint-fetcher>

    <br />
    <section>
        <div style="max-width: 900px; margin-left: auto; margin-right: auto">
            <b-embed
                type="iframe"
                :src="marketingVideoUrl"
                allowfullscreen
            ></b-embed>
        </div>
    </section>
    <br />

    <FAQ></FAQ>

    <billing-address
        v-model:value="billingData"
        @update="billingAddressUpdate"
        submitLabel="Confirm"
    >
        <div class="d-none"></div>
    </billing-address>
</template>

<script>
    import { useMainStore } from "@/main-store";
    import { endpoints } from "@/components/common/api-config.js";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import BillingAddress from "@/components/billing/BillingAddress.vue";
    import PlanList from "@/components/billing/PlanList.vue";
    import BillingAlert from "@/components/billing/BillingAlert.vue";
    import FAQ from "@/components/billing/FAQ.vue";

    export default {
        name: "PlanDashboard",
        components: { EndpointFetcher, BillingAddress, FAQ, PlanList, BillingAlert },
        props: {
            marketingVideoUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                mainStore: useMainStore(),
                billingData: {},
                planSelected: null,
                refresh: false,
            };
        },
        computed: {
            billingEndpoint() {
                // If there's a plan execute a sync to show latest info to the user
                return endpoints["billing"](
                    this.mainStore.profile.user_pk,
                    Boolean(this.billingData?.plan?.uid !== undefined),
                    this.refresh
                );
            },
        },
        methods: {
            planButtonClick(plan) {
                this.planSelected = plan;
                if (!this.billingData || !this.billingData.zip_code) {
                    this.$root.$emit("bv::show::modal", "billing-edit-modal");
                } else {
                    this.setAccessToken();
                    this.showPlanChangeModal(plan);
                }
            },
            setAccessToken() {
                window.Outseta.setAccessToken(this.billingData.token);
            },
            showPlanChangeModal(plan) {
                window.Outseta.profile.open({
                    tabs: "plan",
                    tab: "planChange",
                    stateProps: { planUid: plan.uid, planPaymentTerm: "yearly" },
                });
            },
            billingAddressUpdate(billingData) {
                this.billingData = billingData;
                this.setAccessToken();
                this.showPlanChangeModal(this.planSelected);
            },
            billingDataFetched() {
                if (this.refresh) {
                    window.location.reload();
                }
            },
            handleSubscriptionUpdate() {
                this.refresh = true;
            },
        },
        mounted() {
            // Attach the subscription update event listener
            window.Outseta.on("subscription.update", this.handleSubscriptionUpdate);
        },
        beforeUnmount() {
            // Clean up the event listener when the component is destroyed
            window.Outseta.off("subscription.update", this.handleSubscriptionUpdate);
        },
    };
</script>
