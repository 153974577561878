<template>
  <b-modal :id="modalId" :title="title" body-class="no-padding" size="xl" hide-footer hide-header centered>
    <b-embed type="iframe" aspect="16by9" :src="videoSrc" allowfullscreen></b-embed>
    <a @click="close" class="modal-floating-close" href="javascript:void(0);">
      <i class="fa fa-times fa-lg align-middle" aria-hidden="true"></i>
    </a>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    videoSrc: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
