<template>
    <div class="row">
        <div class="col-12">
            <h2 class="title mb-4 d-flex justify-content-between justify-content-lg-start">
                New Social Post
            </h2>

            <post-new></post-new>

        </div>
    </div>
</template>

<script>
    import PostNew from '@/components/post/PostNew.vue';

    export default {
        name: 'NewPostDashboard',
        components: {
            PostNew,
        },
        data() {
            return {
            }
        },
    }
</script>
