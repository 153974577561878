<template>
    <section class="my-3">
        <h2 class="h4 my-3 text-center">Frequently Asked Questions</h2>

        <div class="border-bottom">
            <accordion-item>
                <template #toggle> Is there a free trial of PRO Membership? </template>
                <template #content>
                    <p>
                        A free trial of PRO Membership is not available. There is no cost to use all the
                        features included in FREE Membership.
                    </p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle>
                    What if I only want to use certain features of PRO Membership, is there a different price?
                </template>
                <template #content>
                    <p>
                        PRO Membership includes a complete bundle of value-added features for the
                        all-inclusive price of $149 per year.
                    </p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle> If I cancel my PRO Membership, do I get a refund? </template>
                <template #content>
                    <p>
                        You may cancel your PRO Membership at any time. All features will remain active for
                        one year from date of purchase and your account will be automatically downgraded at
                        that time. As a prepaid annual service, refunds are not issued.
                    </p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle> What form of payment can I use? </template>
                <template #content>
                    <p>We accept all major credit cards using a secure online payment service.</p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle> Do you charge in US dollars? </template>
                <template #content>
                    <p>
                        Yes, PRO Membership fees are paid in US funds. The credit card company will convert
                        the charge to your local currency.
                    </p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle> How do I renew my PRO Membership? </template>
                <template #content>
                    <p>
                        Renewals will automatically be charged to the credit card used to purchase your
                        current PRO Membership. You can update your billing information at any time in the
                        Account Settings section of your BranchUp profile.
                    </p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle> Do you charge sales tax? </template>
                <template #content>
                    <p>
                        Yes, depending on where you live. The tax amount will be displayed on the checkout
                        page when you purchase your PRO Membership.
                    </p>
                </template>
            </accordion-item>

            <accordion-item>
                <template #toggle> Do you have training on how to use all the PRO features? </template>
                <template #content>
                    <p>
                        Yes, we have video tutorials and documentation available on how to set-up and use the
                        individual features. Click on “Help” on your BranchUp home page navigation menu.
                    </p>
                </template>
            </accordion-item>
        </div>
    </section>
</template>
<script>
    import AccordionItem from "@/components/common/AccordionItem.vue";
    export default {
        name: "FAQ",
        components: { AccordionItem },
    };
</script>
