<template>
    <div class="content">
        <div v-if="suppliers.length" class="row">
            <div class="col-12 border-bottom">
                <accordion-item v-for="(supplier, index) in suppliers" :key="index" @toggle="getLandingPages(supplier.id)">
                    <template #toggle>
                        {{ supplier.supplier_label }}
                    </template>
                    <template #content>
                        <ul>
                            <item-list
                                ref="landingPagesList"
                                :endpoint="getSupplierEndpoint(supplier.id)"
                                :itemComponent="LandingPageItem"
                                :emptyText="noLandingPagesText"
                            ></item-list>
                        </ul>
                    </template>
                </accordion-item>
            </div>
        </div>
        <div v-if="!suppliers.length && !isFetchingContent" class="row">
            <div class="col-12">
                <h3>{{ $t("landingpages.noSuppliersFound") }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import { shallowRef } from 'vue';
    import axios from '../../setup/axios-setup';
    import { endpoints } from './api/EndpointsConfig';
    import AccordionItem from '../common/AccordionItem.vue';
    import ItemList from '../common/ItemList.vue';
    import LandingPageItem from './LandingPageItem.vue';
    import Toast from '../../toast.js';
    const toast = new Toast();
    const profile = window.profileData;

    export default {
        name: 'LandingPagesTree',
        components: {
            AccordionItem,
            ItemList
        },
        data: function () {
            return {
                isFetchingContent: false,
                suppliers: {},
                profilePk: profile.pk,
                LandingPageItem: shallowRef(LandingPageItem),
            };
        },
        methods: {
            getSupplierEndpoint(supplierId) {
                return endpoints.landing_pages(profile.pk, null, supplierId);
            },
            loadItems() {
                this.$refs.landingPagesList.loadItems();
            },
            getLandingPages(supplierId) {
                const supplier = this.suppliers.find(supplier => supplier.id === supplierId);
                if(supplier.isNodeExpanded) {
                    supplier.isNodeExpanded = false;
                    supplier.landingPages = [];
                } else {
                    supplier.isFetchingContent = true;
                    axios.get(
                        endpoints.landing_pages(
                            profile.pk,
                            null,
                            supplierId
                        ))
                        .then((response) => {
                            supplier.landingPages = response.data.results;
                            supplier.totalRows = response.data.count;
                            if(supplier.landingPages.length == 0) {
                                supplier.landingPages = [{ title: this.$t('landingpages.noLandingPagesFound') }];
                            }
                        })
                        .catch((error) => {
                            toast.error(error.response.data.error, "Error");
                        })
                        .finally(() => {
                            supplier.isFetchingContent = false;
                            supplier.isNodeExpanded = true;
                        });
                }
            },
            getSuppliers() {
                this.isFetchingContent = true;
                axios.get(
                    endpoints.suppliers_filtered_list(
                        profile.pk
                    ))
                    .then((response) => {
                        this.suppliers = response.data;
                    })
                    .finally(() => {
                        this.isFetchingContent = false;
                    });
            }
        },
        computed: {
            noLandingPagesText() {
                return this.$t("landingpages.noLandingPagesFound");
            }
        },
        mounted() {
            this.getSuppliers();
        }
    }
</script>
