<template>
<span>
    <div class="text-center mb-2" v-b-tooltip.hover :title="tooltipText">
        Total Audience: {{ audience }} |
        Total Opt outs: {{ optouts }} |
        Total Undeliverables: {{ undeliverables }}
    </div>
    <b-card>
        <b-row>
            <b-col sm="12" md="8" class="mb-1">
                <b-input-group size="xs">
                    <b-form-input
                        class="filter-class"
                        :placeholder="$t('searchPlaceholder')"
                        debounce="500"
                        id="filter-input"
                        type="search"
                        v-model="filter"
                    ></b-form-input>
                    <b-form-select
                        :options="statusOptions"
                        class="h-auto select-class"
                        v-model="selectedOption"
                    ></b-form-select>
                </b-input-group>
            </b-col>
            <b-col sm="12" md="4" class="mb-1 pr-10 text-right">
                <b-button
                    :disabled="readOnly"
                    @click="openAdd($event)"
                    class="btn-bu btn-bu-primary btn-bu-no-icon m-1"
                    variant="primary"
                >
                    {{ $t('addContact') }}
                </b-button>
            </b-col>
        </b-row>
        <b-container fluid>
            <b-table
                :busy-text="$t('loading')"
                :current-page='currentPage'
                :empty-text="$t('emptyText')"
                :emptyFilteredText="$t('emptyFilteredText')"
                :fields="fields"
                :filter="[filter, selectedOption]"
                :items="getContacts"
                hover
                id="contacts-table"
                no-local-sorting
                per-page="20"
                responsive
                show-empty
                stacked="sm"
                stripped
            >
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-center align-items-center">
                        <b-button
                            :disabled="readOnly"
                            @click="openDeleteConfirm(row.item)"
                            class="btn-bu btn-bu-danger btn-bu-no-icon m-1"
                            variant="danger"
                        >
                            <i class="fa fa-trash"></i>
                        </b-button>
                        <b-button
                            :disabled="readOnly"
                            @click="openEdit(row.item)"
                            class="btn-bu btn-bu-warning btn-bu-no-icon m-1"
                            variant="warning"
                        >
                            <i class="fa fa-pencil"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>
            <b-pagination
                :total-rows="totalRows"
                align="center"
                per-page="20"
                size="sm"
                v-model="currentPage"
            ></b-pagination>
        </b-container>

        <contact-delete-modal
            :contact="selected"
            :okCallback="refreshTable"
            v-if="selected"
        ></contact-delete-modal>

        <contact-add-modal
            :contact-list-id="contactListId"
            :okCallback="refreshTable"
        ></contact-add-modal>

        <contact-edit-modal
            :contact-list-id="contactListId"
            :contact="selected"
            :okCallback="refreshTable"
            v-if="selected"
        ></contact-edit-modal>
    </b-card>
</span>
</template>

<script>
    import moment from 'moment-timezone';
    import axios from '../../../setup/axios-setup';
    import Toast from '../../../toast.js';
    const toast = new Toast();
    import { fields, statusOptions } from './contacts-table-config'
    import ContactAddModal from './ContactAddModal.vue';
    import ContactDeleteModal from './ContactDeleteModal.vue';
    import ContactEditModal from './ContactEditModal.vue';

    export default {
        name: 'contacts-table',
        components: { ContactAddModal, ContactDeleteModal, ContactEditModal },
        props: {
            contactListId: {
                type: String,
                required: true,
            },
            'readOnly': {
                type: Boolean,
                required: false,
            },
        },
        data: function () {
            return {
                fields,
                profile: null,
                selected: null,
                currentPage: 1,
                totalRows: 1,
                filter: '',
                audience: 0,
                optouts: 0,
                undeliverables: 0,
                last_export_date: '',
                statusOptions,
                selectedOption: '',
            }
        },
        methods: {
            getProfile() {
                this.profile = window.profileData;
                return window.profileData;
            },
            getContacts(ctx, callback) {
                const { currentPage, sortBy, sortDesc } = ctx;
                const profile = this.profile || window.profileData
                if (!profile) {
                    toast.error(`No profile has been retrieved yet`, 'Error');
                    return
                }

                const api = `/api/contacts/?emarketing=${profile.pk}&contact_list=${this.contactListId}&optout_status=${this.selectedOption}`
                const filterParam = this.filter ? `&filter=${encodeURIComponent(this.filter)}` : '';
                const sortParam = sortBy ? `&ordering=${sortDesc ? '' : '-'}${sortBy}` : ''

                axios.get(`${api}&page=${currentPage}${filterParam}${sortParam}`)
                .then(
                    (response) => {
                        const {
                            audience,
                            optouts,
                            undeliverables,
                            last_export_date,
                            data
                        } = response.data.results;
                        this.totalRows = response.data.count;
                        this.audience = audience;
                        this.optouts = optouts;
                        this.undeliverables = undeliverables;
                        if (last_export_date) {
                            this.last_export_date = moment.tz(
                                last_export_date, "America/Toronto"
                            ).local().format("LL");
                        }
                        callback(data)
                    },
                    (error) => {
                        toast.error(error)
                    }
                );
                return null
            },
            openAdd(event) {
                event.preventDefault()
                this.$bvModal.show('contact-add-modal')
            },
            selectAndOpenModal(contact, modalId) {
                this.selected = contact;
                // prevent race condition between setting the state and telling the modal to open
                this.$nextTick(() => this.$bvModal.show(modalId));
            },
            openDeleteConfirm(contact) {
                this.selectAndOpenModal(contact, 'contact-delete-modal')
            },
            openEdit(contact) {
                this.selectAndOpenModal(contact, 'contact-edit-modal')
            },
            refreshTable() {
                this.$root.$emit('bv::refresh::table', 'contacts-table');
                this.selected = null;
            }
        },
        mounted: function () {
            document.addEventListener('DOMContentLoaded', () => {
                this.getProfile();
            }, { once : true });
        },
        computed: {
            tooltipText() {
                let text = 'The totals refresh each time you "Upload contacts" or "Download contacts".';
                if (this.last_export_date) {
                    text = `Last updated on ${this.last_export_date}. ${text}`;
                }
                return text;
            }
        },
        i18n: {
            messages: {
                en: {
                    addContact: "Add contact",
                    emptyFilteredText: "There are no records matching your search",
                    emptyText: "There are no contacts in this list. Add manually or import with xlsx",
                    loading: "Loading...",
                    searchPlaceholder: "Type to search email, first name, or last name",
                    headers: {
                        email: "Email",
                        firstName: "First name",
                        lastName: "Last name",
                        status: "Status",
                        statusDate: "Status Date",
                        actions: "Actions"
                    }
                },
                es: {
                    addContact: "Agregar contacto",
                    emptyFilteredText: "No hay registros que coincidan con su búsqueda",
                    emptyText: "No hay contactos en esta lista. Agrega manualmente o import con Excel",
                    loading: "Cargando...",
                    searchPlaceholder: "Tipee para buscar por email, nombre o apellido",
                    headers: {
                        email: "Email",
                        firstName: "Nombre",
                        lastName: "Apellido",
                        status: "Estado",
                        statusDate: "Fecha de estado",
                        actions: "Acciones"
                    }
                },
                fr: {
                    addContact: "Ajouter le contact",
                    emptyFilteredText: "Il n'y a aucun enregistrement correspondant à votre recherche",
                    emptyText: "Il n'y a aucun contact dans cette liste. Ajouter manuellement ou importer avec xlsx",
                    loading: "Chargement...",
                    searchPlaceholder: "Tapez pour rechercher par email, prénom ou nom",
                    headers: {
                        email: "E-mail",
                        firstName: "Prénom",
                        lastName: "Nom de famille",
                        status: "Statut",
                        statusDate: "Date du statut",
                        actions: "Actions"
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-class {
        width: 60%!important;
    }

    .select-class {
        width: 40%!important;
    }
</style>
