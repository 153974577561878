// Legacy toast class. Analyze to refactor when possible.

import $ from 'jquery';

export default class Toast {
    constructor(delay = 5000, container = '#toaster') {
        this.container = container;
        this.delay = delay;

        $('body').prepend(
            "<div aria-live=\"polite\" aria-atomic=\"true\" style=\"position: relative;\">\n" +
            "    <div style=\"position: fixed; top: 0; right: 0; z-index: 1070;\" id=\"toaster\"></div>\n" +
            "</div>")

        if (!$(this.container).length) {
            console.error('Cannot find container: ' + this.container);
        }
    }

    toast(background, message, title) {

        let toast =
            "<div class=\"toast d-flex mt-3 mr-3 text-white " + background + " border-0\" role=\"alert\" aria-live=\"assertive\" aria-atomic=\"true\">\n" +
            "    <div class=\"toast-body\">\n" +
            "        <span class=\"font-weight-bold bigger-text text-white d-block\">" + title + "</span>\n" +
            message +
            "    </div>\n" +
            "    <button type=\"button\" class=\"ml-auto p-2 close text-white\" data-dismiss=\"toast\" aria-label=\"Close\">\n" +
            "        <svg class=\"bi bi-x\" width=\"1em\" height=\"1em\" viewBox=\"0 0 16 16\" fill=\"currentColor\"\n" +
            "             aria-hidden=\"true\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
            "            <path fill-rule=\"evenodd\"\n" +
            "                  d=\"M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z\"\n" +
            "                  clip-rule=\"evenodd\"/>\n" +
            "            <path fill-rule=\"evenodd\"\n" +
            "                  d=\"M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z\"\n" +
            "                  clip-rule=\"evenodd\"/>\n" +
            "        </svg>\n" +
            "    </button>\n" +
            "</div>";

        $(this.container).prepend(toast)
        $('.toast').toast({delay: this.delay});
        $(this.container).children('.toast').first().toast('show');

        // remove element when hidden
        $(this.container).children('.toast').on('hidden.bs.toast', function () {
            $(this).remove();
        })

        return toast;
    }

    error(message, title) {
        return this.toast('bg-danger', message, title)
    }

    warning(message, title) {
        return this.toast('bg-warning', message, title)
    }

    success(message, title) {
        return this.toast('bg-primary', message, title);
    }

    danger(message, title) {
        return this.error(message, title);
    }
}
