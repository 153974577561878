<template>
    <b-alert v-if="!connected" show variant="danger">
        <p class="text-center">
            <b class="text-center">{{ $t('connection.broken.desc') }}</b>
        </p>
        <p class="text-center" v-if="showSettingButton">
            <a
                :href="settingsURL"
                class="btn btn-primary">
                <i class="fa mr-2" :class="icon"></i>
                {{ $t('common.reviewSettings') }}
            </a>
        </p>
    </b-alert>
</template>
<script>
    import axios from '@/setup/axios-setup.js';
    import { connectionConfigValues } from '@/components/apps-connection-config.js';
    import { views } from '@/components/common/views-config.js';
    import { appStringProp } from '@/utils/props.js';
    import { endpoints } from '@/components/common/api-config.js'

    export default {
        name: 'ConnectionAlert',
        props: {
            app: appStringProp,
            showSettingButton: {
                type: Boolean,
                default: true,
            }
        },
        data(){
            return {
                connected: true,
                settingsURL: views.app_settings(window.profileData.slug, this.app),
                icon: connectionConfigValues[this.app].icon,
                appName: connectionConfigValues[this.app].name,
            }
        },
        methods: {
            checkConnection(){
                const endpoint = endpoints['profilesocial-status'](window.profileData.pk, this.app)
                axios.get(endpoint)
                    .then((response) => {
                        this.connected = response.data.connected;
                    })
                    .catch(() => {
                        this.connected = false;
                    });
            }
        },
        computed: {
            application(){
                return window.profileData.applications[this.app];
            }
        },
        mounted(){
            this.checkConnection();
        }
    }
</script>
