<template>
    <confirm-and-wait-modal
        :endpoint="endpoint"
        :id="`editSupplier${supplier.id}`"
        :okTitle="$t('supplier.okTitle')"
        :params="{ ...form }"
        :successMessage="$t('supplier.success')"
        :title="$t('supplier.title')"
        method="patch"
    >
        <div>
            <b-form>
                <b-form-group>
                    <div class="card-title">
                        <h5>{{ params.name }}</h5>
                    </div>
                    {{ params.label }}
                    <b-form-input
                        id="supplier-input-value"
                        v-model="form.cobranded_value"
                    ></b-form-input>
                    <div>&nbsp;</div>
                    <b-form-checkbox
                        id="supplier-input-status"
                        v-model="form.status"
                    >
                        {{ params.disclaimer }}
                    </b-form-checkbox>
                    <div v-if="params.profileCount > 1">&nbsp;</div>
                    <b-form-checkbox
                        v-if="params.profileCount > 1"
                        id="supplier-input-apply-to-all"
                        v-model="form.apply_to_all"
                    >
                        {{ $t("supplier.applyAll") }}
                    </b-form-checkbox>
                </b-form-group>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import { endpoints } from "@/components/common/api-config.js";
    import { useMainStore } from "@/main-store";
    import ConfirmAndWaitModal from "@/components/common/ConfirmAndWaitModal.vue";

    export default {
        name: "CobrandedEdit",
        components: { ConfirmAndWaitModal },
        props: {
            id: {
                type: String,
                default: "cobranded-edit",
            },
            supplier: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                form: {
                    cobranded_value: "",
                    status: false,
                    apply_to_all: false,
                },
                mainStore: useMainStore(),
            };
        },
        methods: {
            init(supplier) {
                const { cobranded_value, cobranded_disclaimer_date } = supplier || {};
                this.form = {
                    cobranded_value: cobranded_value,
                    status: cobranded_disclaimer_date !== "",
                    apply_to_all: false,
                };
            },
        },
        computed: {
            params() {
                if (!this.supplier) {
                    return {};
                }
                return {
                    name: this.supplier.supplier_label,
                    label: this.supplier.cobranded_label,
                    disclaimer: this.supplier.cobranded_disclaimer,
                    profileSupplierPk: this.supplier.profilesupplier_id,
                    supplierPk: this.supplier.id,
                    profilePk: this.mainStore.profile.pk,
                    profileCount: this.mainStore.profile.profiles_count,
                };
            },
            endpoint() {
                return endpoints["profilesupplier-update"](
                    this.params.profileSupplierPk,
                    this.params.profilePk,
                    this.params.supplierPk
                );
            },
        },
    };
</script>
