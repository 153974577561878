<template>
    <div id="alerts-widget" class="card mb-3">
        <div class="card-body">
            <h5>
                <i class="fa fa-bell mr-1"></i>
                {{ $t("notifications.alerts.title") }}
                <span :class="['badge', alerts.length != 0 ? 'badge-danger' : 'badge-success']">{{ alerts.length }}</span>
            </h5>
            <ul v-for="(alert, index) in alerts" :key="alert.slug" class="list-unstyled mb-0">
                <li :class="[
                    'd-flex', 'justify-content-between', 'align-items-center', 'alert-item',
                    index === 0 ? 'mt-3' : 'mt-0', index === alerts.length - 1 ? 'mb-0' : 'mb-3'
                ]">
                    <span class="d-flex align-items-center">{{ alert.text }}</span>
                    <small class="d-flex text-right">
                        <a v-if="alert.link && alert.cta" :href="alert.link" class="pl-2 reset-link">{{ alert.cta }}</a>
                    </small>
                </li>
            </ul>
            <div class="row mt-3" v-if="alerts.length === 0">
                <div class="col-12">{{ $t("notifications.alerts.noAlerts") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from '@/main-store';

    const profile = window.profileData;
    const baseURL = window.location.origin;

    export default {
        name: 'AlertsWidget',
        data() {
            return {
                alerts: [],
                staticAlerts: {
                    'email_not_verified': {
                        priority: 1,
                        slug: 'email_not_verified',
                        text: this.$t('notifications.alerts.emailNotVerified'),
                        cta: this.$t('notifications.alerts.emailNotVerifiedCta'),
                        link: baseURL + '/accounts/account_settings/',
                    },
                    'no_apps_connected': {
                        priority: 2,
                        slug: 'no_apps_connected',
                        text: this.$t('notifications.alerts.noAppsConnected'),
                        cta: this.$t('notifications.alerts.noAppsConnectedCta'),
                        link: null,
                    },
                    'facebook_not_connected': {
                        priority: 3,
                        slug: 'facebook_not_connected',
                        text: this.$t('notifications.alerts.facebookDisconnected'),
                        cta: this.$t('notifications.alerts.facebookDisconnectedCta'),
                        link: null,
                    },
                    'instagram_not_connected': {
                        priority: 4,
                        slug: 'instagram_not_connected',
                        text: this.$t('notifications.alerts.instagramDisconnected'),
                        cta: this.$t('notifications.alerts.instagramDisconnectedCta'),
                        link: null,
                    },
                    'linkedin_not_connected': {
                        priority: 5,
                        slug: 'linkedin_not_connected',
                        text: this.$t('notifications.alerts.linkedinDisconnected'),
                        cta: this.$t('notifications.alerts.linkedinDisconnectedCta'),
                        link: null,
                    }
                },
                mainStore: useMainStore(),
            };
        },
        methods: {
            getStaticAlerts() {
                // Verify your email alert
                if (profile.email_verification === false) {
                    this.alerts.push(this.staticAlerts['email_not_verified']);
                }
            },
            checkAlert(newStore, staticAlert) {
                staticAlert.link = newStore.connectURL;

                if (newStore.installed && !newStore.connected) {
                    let existingAlert = this.alerts.find(alert => alert.slug === staticAlert.slug);
                    if (existingAlert) {
                        existingAlert.link = newStore.connectURL;
                    } else {
                        this.alerts.push(staticAlert);
                    }
                }
            }
        },
        mounted() {
            this.getStaticAlerts()
        },
        computed: {
            facebookStore() {
                return this.mainStore.facebook;
            },
            instagramStore() {
                return this.mainStore.instagram;
            },
            linkedinStore() {
                return this.mainStore.linkedin;
            }
        },
        watch: {
            facebookStore: {
                handler(newStore) {
                    this.checkAlert(newStore, this.staticAlerts['facebook_not_connected']);
                },
                deep: true
            },
            instagramStore: {
                handler(newStore) {
                    this.checkAlert(newStore, this.staticAlerts['instagram_not_connected']);
                },
                deep: true
            },
            linkedinStore: {
                handler(newStore) {
                    this.checkAlert(newStore, this.staticAlerts['linkedin_not_connected']);
                },
                deep: true
            },
            alerts(newAlerts) {
                if (newAlerts.length > 0) {
                    // Order by priority
                    newAlerts.sort((a, b) => a.priority - b.priority);

                    // Show badge on profile picture
                    document.getElementById('profile-alerts-badge').classList.remove('d-none');
                    document.getElementById('profile-alerts-message').classList.remove('d-none');
                }
            }
        }
    }
</script>

<style>
    #profile-alerts-badge i {
        background-color: #ffffff;
        border-radius: 20px;
        padding: 1px;
    }
</style>
