export const connectionConfigValues = {
    facebook: {
        id: "facebook",
        provider: "fbpage",
        name: "Facebook",
        icon: "fa-facebook-square",
        order: 1,
        hashtagEnabled: true,
    },
    instagram: {
        id: "instagram",
        provider: "instagram",
        name: "Instagram",
        icon: "fa-instagram",
        plan: "pro",
        order: 2,
        hashtagEnabled: true,
    },
    linkedin: {
        id: "linkedin",
        provider: "linkedin",
        name: "LinkedIn",
        icon: "fa-linkedin-square",
        order: 3,
        hashtagEnabled: true,
    },
    emarketing: {
        id: "emarketing",
        name: "Client Newsletters",
        icon: "fa-envelope-o",
        order: 4,
    },
};
