<template>
    <confirm-and-wait-modal
        okTitle="Delete"
        successMessage="Deleted!"
        title="You are about to delete"
        cancel-variant="light"
        method="delete"
        ok-variant="danger">
        <div>
            <img
                v-lazy="{ src: post.data.image.image_small, loading: post.data.image.image_thumb }"
                alt=""
                class="img-fluid mb-2">
            <p>{{ post.message }}</p>
        </div>

    </confirm-and-wait-modal>
</template>

<script>
import ConfirmAndWaitModal from '../../common/ConfirmAndWaitModal.vue';
export default {
  components: { ConfirmAndWaitModal },
    name: 'GeneratedPostDeleteModal',
    props: {
        post:{
            type: Object,
            required: true
        }
    },
};
</script>
