<template>
    <endpoint-fetcher
        class="d-none"
        :endpoint="planEndpoint"
        v-model:fetchedData="plans"
        :processResponse="(response) => response.data.results"
    >
    </endpoint-fetcher>

    <section class="mb-3">
        <div class="card-deck text-center">
            <div
                v-for="plan in plans"
                :key="plan.uid"
                class="card mb-4 pt-4"
            >
                <p
                    v-if="isSelectedPlan(plan)"
                    class="billing-plan__active-badge"
                >
                    Your Current Membership
                </p>

                <div class="card-body">
                    <h5
                        class="h5 mb-0 text-uppercase"
                        :class="`badge-membership-title-${plan.slug}`"
                    >
                        {{ plan.name }}
                    </h5>

                    <section class="mt-3 {% if billing_plan.slug == 'free' %}mb-4{% else %}mb-2{% endif %}">
                        <div
                            style="height: 120px"
                            class="d-flex justify-content-center align-items-center flex-column"
                        >
                            <template v-if="plan.slug === 'free'">
                                <p class="billing-plan-price billing-plan-price--annual">
                                    <span class="billing-plan-price__value">FREE</span>
                                </p>
                                <p class="billing-plan-price billing-plan-price--month">
                                    <small>Always Free</small>
                                </p>
                            </template>
                            <template v-else>
                                <p class="billing-plan-price billing-plan-price--annual">
                                    <span class="billing-plan-price__value"
                                        >${{ plan.data.AnnualRate.toFixed(2) }}</span
                                    >
                                </p>
                                <p class="billing-plan-price billing-plan-price--month">
                                    <small>Per Year</small>
                                </p>
                            </template>
                        </div>
                    </section>

                    <div
                        class="plan-card-body text-left my-4 checked-list"
                        v-html="plan.data.Description"
                    ></div>
                </div>

                <div class="card-footer card-footer--no-padding card-footer--transparent">
                    <!-- Main action button -->
                    <div class="text-center px-5">
                        <b-button
                            variant="bu"
                            :disabled="isPlanDisabled(plan)"
                            @click="planButtonClick(plan)"
                            class="btn-bu-no-icon w-100 mt-0 d-flex justify-content-center"
                            :class="getPlanButtonClasses(plan)"
                        >
                            {{ getPlanButtonLabel(plan) }}
                        </b-button>
                    </div>
                    <!-- /Main action button -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import { endpoints } from "@/components/common/api-config.js";
    import { useMainStore } from "@/main-store";

    export default {
        name: "PlanList",
        emits: ["plan-click"],
        components: { EndpointFetcher },
        props: {
            billingData: {
                // A BillingData object is expected with all the serialized data given by the billing API
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                planEndpoint: endpoints["plan-list"],
                plans: [],
                mainStore: useMainStore(),
            };
        },
        computed: {
            currentPlanSlug() {
                if (this.billingData?.plan?.slug) {
                    return this.billingData?.plan?.slug;
                }
                if (this.mainStore.profile.plan) {
                    return this.mainStore.profile.plan;
                }
                return "free";
            },
        },
        methods: {
            isSelectedPlan(plan) {
                return plan.slug === this.currentPlanSlug;
            },
            getPlanButtonClasses(plan) {
                return {
                    "btn-bu-primary-outline text-primary": this.isSelectedPlan(plan),
                    "btn-bu-primary": !this.isSelectedPlan(plan),
                };
            },
            isPlanDisabled(plan) {
                return this.isSelectedPlan(plan) && plan.slug === "free";
            },
            getPlanButtonLabel(plan) {
                if (this.isSelectedPlan(plan)) {
                    return "VIEW";
                } else {
                    if (plan.slug === "free") {
                        return "DOWNGRADE";
                    } else {
                        return "UPGRADE NOW";
                    }
                }
            },
            planButtonClick(plan) {
                this.$emit("plan-click", plan);
            },
        },
    };
</script>
