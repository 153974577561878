<template>
    <confirm-and-wait-modal
        okTitle="Schedule"
        title="Schedule Post"
        cancel-variant="light"
        :ok-disabled="!isFormOk"
        method="post"
        :params="form">
        <div>
            <b-form>
                <social-network-picker v-model:value="form.applications"></social-network-picker>
                <date-picker v-model:value="form.fire_at"></date-picker>
            </b-form>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import DatePicker from '@/components/common/DatePicker.vue';
    import ConfirmAndWaitModal from '@/components/common/ConfirmAndWaitModal.vue';
    import SocialNetworkPicker from '@/components/common/SocialNetworkPicker.vue';

    export default {
        name: 'GeneratedPostScheduleModal',
        props: {
            post:{
                type: Object,
                required: true
            }
        },
        components: {
            ConfirmAndWaitModal,
            DatePicker,
            SocialNetworkPicker,
        },
        data() {
            return {
                profile: window.profileData,
                form: {
                    applications: null,
                    fire_at: null,
                },
                timeConfirm: false,
            }
        },
        computed: {
            isFormOk(){
                return this.form.applications && this.form.applications.length > 0 && this.form.fire_at
            }
        },
        methods: {
            updateModelValue($event){
                this.form.fire_at = $event
            }
        }
    };
</script>
