<template>
    <endpoint-fetcher
        :endpoint="endpoint"
        :process-response="getPreviewFromResponse"
        no-data-message="No preview available"
        error-message="Unable to get the campaign preview"
        v-model:fetchedData="htmlContent"
    >
        <b-embed type="iframe" :src="iframeSrc" aspect="1by1"></b-embed>
    </endpoint-fetcher>
</template>

<script>
    import EndpointFetcher from '@/components/common/EndpointFetcher.vue';
    import { routes } from './campaigns-config';

    export default {
        props: {
            campaignId: {
                type: String,
                required: true,
            },
        },
        components: {
            EndpointFetcher
        },
        data() {
            return {
                htmlContent: ''
            }
        },
        methods: {
            getPreviewFromResponse(response) {
                return response.data.htmlbody;
            },
        },
        computed: {
            endpoint() {
                return routes.preview(this.$props.campaignId);
            },
            iframeSrc() {
                // Convert the HTML content to a data URL
                return "data:text/html;charset=utf-8," + encodeURIComponent(this.htmlContent);
            }
        }
    };
</script>
