<template>
    <b-button variant="outline-dark" @click="create_post_automatic()" :disabled="creating_post" class="mb-2 w-100">
        <i class="fa fa-random" v-if="!creating_post"></i>
        <i class="fa fa-refresh fa-spin" v-if="creating_post"></i>
        {{ label }}
    </b-button>
</template>

<script>
    import axios from '../../../setup/axios-setup';
    import Toast from '../../../toast.js';
    const toast = new Toast();

    export default {
        name: 'GeneratedPostCreateAutomatic',
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            okCallback: {
                type: Function,
                required: true,
            },
            label: {
                type: String,
                default: 'New automatic AI post'
            },
        },
        data() {
            return {
                creating_post: false,
            };
        },
        methods: {
            create_post_automatic() {
                this.creating_post = true;
                axios.post(this.endpoint, {}).then((response) => {
                    toast.success("New post created!", "Success");
                    this.creating_post = false;
                    this.okCallback(response)
                }).catch(() => {
                    toast.error("Unable to create post!", "Error");
                    this.creating_post = false;
                });
            },
        }
    };
</script>
