/***
 * Vanilla JS debounce function.
 * @param callback a function to be debounced
 * @param delay Milliseconds before actually calling the callback
 * @returns the debounced version of the callback function
 * Every time the debounced function is called, the delay "timer" restarts. Only when "delay" milliseconds
 * pass without the debounced function being called again, will the callback actually be called (in its latest
 * version)
 */
export const debounce = (callback, delay) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, delay);
    };
}
