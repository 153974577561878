<template>
    <div class="col-12 text-center">
        <p class="mt-5">No posts found for this search</p>
        <p class="text-muted">Try removing filters or searching for something else</p>
    </div>
</template>
<script>
    export default {
        name: "PostListSelectorEmpty",
    };
</script>
