<template>
    <item-list
        ref="list"
        :endpoint="endpoint"
        :itemComponent="PostSocial"
        :emptyComponent="emptyComponent"
        :emptyText="emptyText"
    ></item-list>
</template>

<script>
    import { shallowRef, h } from 'vue';
    import { endpoints } from '@/components/common/api-config.js';
    import ItemList from '@/components/common/ItemList.vue';
    import PostSocial from '@/components/post/PostSocial.vue';
    import EmptyPost from '@/components/post/EmptyPost.vue';
    import { connectionConfigValues } from '@/components/apps-connection-config.js';
    import { appStringProp } from '@/utils/props.js';

    const VALID_QUERIES = ['upcoming', 'published', 'deleted', 'top']

    export default {
        name: 'PostList',
        components: {
            ItemList,
        },
        props: {
            app: appStringProp,
            pageId: {
                type: String,
                required: true
            },
            dateFrom: {
                // DateTime or Moment is expected
                type: Object,
                required: false
            },
            dateUntil: {
                // DateTime or Moment is expected
                type: Object,
                required: false
            },
            createdFrom: {
                type: Date,
                required: false
            },
            createdUntil: {
                type: Date,
                required: false
            },
            queryType: {
                type: String,
                required: false,
                validator: function(value) {
                    return VALID_QUERIES.includes(value);
                }
            },
        },
        data() {
            return {
                EmptyPost: shallowRef(h(EmptyPost, {metrics: this.$props.queryType === 'top'})),
                PostSocial: shallowRef(h(PostSocial, {app: this.$props.app}))
            };
        },
        computed: {
            endpoint() {
                // upcoming or default case
                let provider = connectionConfigValues[this.$props.app].provider;
                let status = 0;
                let deleted = false;
                let ordering = 'fire_at';
                let top = null;
                let dateFrom = this.dateFrom;
                let dateUntil = this.dateUntil;
                let createdFrom = null;
                let createdUntil = null;

                switch (this.queryType) {
                    case 'upcoming':
                        createdFrom = this.createdFrom;
                        createdUntil = this.createdUntil;
                        break;
                    case 'published':
                        status = 10;
                        ordering = '-fire_at';
                        break;
                    case 'deleted':
                        deleted = true;
                        ordering = '-fire_at'
                        status = null;
                        dateFrom = null;
                        dateUntil = null;
                        break;
                    case 'top':
                        top = true;
                        status = 10;
                        break;
                    default:
                        break;
                }
                return endpoints['scheduledposting-list'](
                    this.pageId,
                    provider,
                    status,
                    deleted,
                    dateFrom,
                    dateUntil,
                    createdFrom,
                    createdUntil,
                    top,
                    ordering,
                );
            },
            emptyComponent() {
                return !this.queryType || ['upcoming', 'top'].includes(this.queryType) ? this.EmptyPost : null;
            },
            emptyText() {
                let text = null;
                if (this.queryType && this.queryType !== 'upcoming') {
                    switch (this.queryType) {
                        case 'published':
                            text = this.$t('post.empty.published');
                            break;
                        case 'deleted':
                            text = this.$t('post.empty.deleted');
                            break;
                        case 'top':
                            text = this.$t('post.empty.top');
                            break;
                        default:
                            break;
                    }
                }
                return text;
            }
        },
        watch: {
            app(newApp) {
                this.PostSocial = shallowRef(h(PostSocial, {app: newApp}));
            }
        },
    }
</script>
