<template>
    <div class="row mb-5">
        <div class="col-12">
            <p class="mt-2 mb-4">
                {{ mainStore.profile.user_first_name }} {{ mainStore.profile.user_last_name }} ({{
                    mainStore.profile.user_email
                }})
            </p>

            <div>
                <b-tabs
                    content-class="mt-3"
                    v-model="tabIndex"
                >
                    <b-tab
                        title="Billing"
                        active
                    ></b-tab>
                    <b-tab title="Membership"></b-tab>
                </b-tabs>
            </div>

            <endpoint-fetcher
                :endpoint="billingEndpoint"
                v-model:fetchedData="billingData"
            >
                <div :class="{ 'd-none': !showBilling }">
                    <billing-address v-model:value="billingData"></billing-address>
                    <hr />
                </div>

                <div id="profile-embed"></div>
            </endpoint-fetcher>
        </div>
    </div>
</template>
<script>
    import { useMainStore } from "@/main-store";
    import { endpoints } from "@/components/common/api-config.js";
    import { views } from "@/components/common/views-config.js";
    import EndpointFetcher from "@/components/common/EndpointFetcher.vue";
    import BillingAddress from "@/components/billing/BillingAddress.vue";
    import moment from "moment-timezone";

    export default {
        name: "BillingDashboard",
        components: { EndpointFetcher, BillingAddress },
        data() {
            return {
                mainStore: useMainStore(),
                billingData: {},
                tabIndex: 0,
            };
        },
        computed: {
            billingEndpoint() {
                return endpoints["billing"](this.mainStore.profile.user_pk, true);
            },
            planView() {
                return views.profile_plan(this.mainStore.profile.slug);
            },
            renewalDate() {
                if (this.billingData?.subscription_data?.RenewalDate) {
                    return moment(this.billingData.subscription_data.RenewalDate).format("LLL");
                }
                return null;
            },
            showBilling() {
                return this.tabIndex == 0;
            },
        },
        watch: {
            tabIndex(newTabIndex) {
                if (newTabIndex == 0) {
                    this.openBillingModal();
                }
                if (newTabIndex == 1) {
                    this.openPlanModal();
                }
            },
            billingData(newBillingData) {
                window.Outseta.setAccessToken(newBillingData.token);
            },
        },
        methods: {
            openPlanModal() {
                window.Outseta.profile.open({
                    tabs: "plan",
                    tab: "plan",
                });
            },
            openBillingModal() {
                window.Outseta.profile.open({
                    tabs: "billing",
                    tab: "billing",
                });
            },
        },
    };
</script>
