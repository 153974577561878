<template>
    <a class="mb-4" href="javascript:void(0);" @click="selectPost()">
        <article class="card card--shadow-border mb-4">
            <section class="card-body">
                <p class="text-breakspaces mb-0 text-dark text-fixed-height" >{{ post.message }}</p>
            </section>
            <main class="card-footer card-footer--no-padding card-footer--no-link">
                <video
                    v-if="post.video"
                    :src="post.video"
                    :poster="post.image"
                    controls
                    controlsList="nodownload"
                    class="embed-responsive embed-responsive-21by9 post-video">
                </video>

                <div
                    v-if="!post.video && post.image"
                    class="overflow-hidden rounded-bottom">
                    <a
                        v-if="post.external_link || post.link"
                        :href="post.external_link || post.link"
                        target="_blank"
                        :title="post.link_title">
                        <img
                            v-lazy="{ src: post.image }"
                            alt=""
                            class="img-fluid">
                        <div
                            :class="'post_clicks' in post.stats || 'post_impressions_unique' in post.stats || 'post_engaged_users' in post.stats ? '' : 'rounded-bottom'"
                            class="border">
                            <p class="px-3 pt-2 pb-1 mb-0 text-uppercase">
                                <small>{{ post.domain }}</small>
                            </p>
                            <p v-if="post.title" class="mb-0 px-3 pb-3 h5">
                                {{ post.title }}
                            </p>
                        </div>
                    </a>
                    <img
                        v-if="!post.link && !post.external_link"
                        v-lazy="{ src: post.image }"
                        alt=""
                        class="img-fluid">
                </div>

            </main>
        </article>
    </a>
</template>

<script>
    import { formatDate } from '@/utils/datetime.js';

    export default {
        name: 'PostListSelectorItem',
        emits: ['select-item'],
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                post: this.parseItem(this.$props.item),
                selected: false,
            };
        },
        methods: {
            parseItem(item){
                if (item && item.fire_at) {
                    item.fire_at = formatDate.parseWithTimeZone(item.fire_at);
                    item.fire_at_formatted = item.fire_at.format("LLLL");
                }
                return item;
            },
            selectPost(){
                this.selected = !this.selected;
                this.$emit('select-item', this.post);
            }
        },
    }
</script>
<style lang="scss" scoped>
    .text-fixed-height {
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
