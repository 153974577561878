<template>
    <b-modal ref="this-modal" cancel-variant="light" hide-footer :title="appTitle">
        <post-social
            v-if="app !== 'emarketing'"
            :item="item"
            :app="app"
            @update-item="updatePost"
            @add-item="updatePost">
        </post-social>

        <post-emarketing
            v-if="app === 'emarketing'"
            :item="item"
            :app="app"
            @update-item="updatePost"
            @add-item="updatePost">
        </post-emarketing>
    </b-modal>
</template>

<script>
import PostSocial from '@/components/post/PostSocial.vue';
import PostEmarketing from '@/components/post/PostEmarketing.vue';

export default {
    name: 'CalendarModal',
    emits: ['update:post'],
    components: {
        PostSocial,
        PostEmarketing,
    },
    data () {
        return {
            app: null,
            item: null,
        }
    },
    computed: {
        appTitle() {
            if (!this.app) {
                return '';
            }
            return window.profileData.applications[this.app].name;
        },
    },
    methods: {
        show(app, item) {
            this.app = app;
            this.item = item;
            this.$refs['this-modal'].show();
        },
        updatePost(post) {
            this.item = post;
            this.item.provider = this.app;
            this.$emit('update:post', post);
            this.$refs['this-modal'].hide();
        },
    },
};
</script>
