<template>
    <div
        v-if="billingData?.upcoming_plan"
        class="row justify-content-center mb-3"
    >
        <div class="col-md-8">
            <div class="alert alert-info text-center">
                <i class="fa fa-exclamation-circle"></i>
                Your membership is scheduled to change to <b>{{ billingData.upcoming_plan.name }}</b> at the
                end of the current billing cycle on {{ effectiveDate }}.
            </div>
        </div>
    </div>
</template>

<script>
    import { formatDate } from "@/utils/datetime.js";

    export default {
        name: "BillingAlert",
        props: {
            billingData: {
                type: Object,
                required: true,
            },
        },
        computed: {
            effectiveDate() {
                if (!this.billingData.account_data.LatestSubscription.StartDate) {
                    return null;
                }
                return formatDate.simple(this.billingData.account_data.LatestSubscription.StartDate);
            },
        },
    };
</script>
