<template>
    <endpoint-fetcher
        :endpoint="endpoint"
        v-model:fetched-data="endpointData"
    >
        <b-table :items="items" :fields="fields" bordered show-empty emptyText="No data available">
            <template v-slot:cell(link_name)="row">
                <a :href="formatURL(row.item.url)">{{ row.item.link_name }}</a>
            </template>
        </b-table>
    </endpoint-fetcher>
</template>

<script>
    import EndpointFetcher from '@/components/common/EndpointFetcher.vue';
    import { numToPercentage } from '@/utils/numbers';
    import { routes } from './campaigns-config';

    export default {
        name: 'email-campaign-links',
        components: { EndpointFetcher },
        props: {
            campaignId: {
                type: String,
                required: true
            },
            supplier: {
                type: Object,
                required: false
            }
        },
        data() {
            return {
                endpointData: null,
                items: [],
                fields: [
                    { key: 'link_name', label: 'Link Name', sortable: true },
                    { key: 'supplier_name', label: 'Supplier', sortable: true },
                    {
                        key: 'click_count',
                        label: 'Clicks',
                        thClass: "text-center",
                        tdClass: "text-center",
                        sortable: true
                    },
                    {
                        formatter: value => numToPercentage(value) || '-',
                        key: 'click_percentage',
                        label: '%',
                        thClass: "text-center",
                        tdClass: "text-center",
                        sortable: true
                    },
                ],
            };
        },
        methods: {
            formatURL(url) {
                // to prevent accidental relative urls and support urls in the form of "myweb.com"
                if (url.startsWith("http://") || url.startsWith("https://")) {
                    return url;
                } else {
                    return "http://" + url;
                }
            }
        },
        computed: {
            endpoint() {
                const supplierId = this.supplier ? this.supplier.pk : undefined;
                return routes.links(this.campaignId, supplierId)
            }
        },
        watch: {
            endpointData(newValue) {
                this.items = newValue || [];
            }
        }
    };
</script>
