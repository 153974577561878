<template>
    <div class="checkbox-container mt-2 mb-4">
        <template v-for="(app, index) in apps" :key="index">
            <b-form-checkbox
                switch
                :id="app.id" v-model="selectedApps" :value="app.id">
                <i :class="app.icon" class="fa"></i>
                {{ app.name }}
            </b-form-checkbox>
        </template>
        <b-button v-if="showAllAppsButton" @click="selectAllApps()" size="sm" variant="outline-dark">Select all</b-button>
    </div>
</template>

<script>
    import { connectionConfigValues } from '@/components/apps-connection-config.js';
    export default {
        name: 'AppMultiSelect',
        emits: ['update:value'],
        props: {
            value: {
                type: Array,
                required: true
            },
            showAllAppsButton: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                selectedApps: this.value,
            }
        },
        computed: {
            profile() {
                return window.profileData;
            },
            apps(){
                return Object.fromEntries(
                    Object.entries(connectionConfigValues).filter(app => this.profile.applications[app[1].id].is_enabled)
                );
            }
        },
        methods: {
            selectAllApps() {
                const appsKeys = Object.keys(this.apps)
                this.selectedApps = appsKeys;
            }
        },
        watch: {
            selectedApps(newValue) {
                this.$emit('update:value', newValue);
            },
        },
        mounted() {
            const appsKeys = Object.keys(this.apps)
            if(this.selectedApps.length == 0 && appsKeys.length > 0) {
                // if no apps are selected, select all apps by default
                this.selectedApps = appsKeys;
            }
        }
    };
</script>
<style>
    .checkbox-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px; /* Optional: to add space between checkboxes */
    }
</style>
