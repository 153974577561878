<template>
    <b-overlay :show="isDisabled" rounded="lg" spinner-type="none">
        <confirm-and-wait ref="saveAiPreferences" :endpoint="endpoint_generator_profile_detail" :params="form" method="put">
            <div class="row">
                <div class="col-12">
                    <b-form>
                        <b-form-group :label="$t('generator.profile.voiceTone')" label-for="voice_tone"
                            :description="$t('generator.profile.voiceToneDescription')">
                            <b-form-select id="voice_tone" v-model="form.voice_tone" :options="voice_tone_options"></b-form-select>
                        </b-form-group>

                        <b-form-group :label="$t('generator.profile.writtingStyle')" label-for="writing_style"
                            :description="$t('generator.profile.writtingStyleDescription')">
                            <b-form-select id="writing_style" v-model="form.writing_style" :options="writing_style_options"></b-form-select>
                        </b-form-group>

                        <b-form-checkbox id="use-agency-name" v-model="form.use_agency_name" name="use-agency-name">
                            {{ $t('generator.profile.useAgencyName') }}
                        </b-form-checkbox>

                        <b-form-group class="mt-4" label="Destinations" label-for="destinations"
                            description="This list of destinations is also defined in your seller information page. It will be used to select the images generated by the AI.">
                            <div id="destinations" class="row">
                                <div class="col-12 mb-2">
                                    <label for="destination1">What is the #1 destination/region you sell? *</label>
                                    <b-form-select id="destination1" v-model="form.destination1"
                                        :options="destination_options"></b-form-select>
                                </div>
                                <div class="col-6 mb-2">
                                    <label for="destination2">#2 destination/region?</label>
                                    <b-form-select id="destination2" v-model="form.destination2"
                                        :options="destination_options"></b-form-select>
                                </div>
                                <div class="col-6 mb-2">
                                    <label for="destination3">#3 destination/region?</label>
                                    <b-form-select id="destination3" v-model="form.destination3"
                                        :options="destination_options"></b-form-select>
                                </div>
                            </div>
                        </b-form-group>

                        <b-button class="btn-bu btn-bu-secondary btn-bu-no-icon" @click="savePreferences()">{{ $t('common.saveChanges') }}</b-button>
                    </b-form>
                </div>
            </div>
        </confirm-and-wait>
    </b-overlay>

    <endpoint-fetcher v-if="!isDisabled" class="d-none" :endpoint="endpoint_generator_profile_detail"
        :process-response="displayProfileDetails" :show-loading="false"></endpoint-fetcher>

    <endpoint-fetcher v-if="!isDisabled" class="d-none" :endpoint="endpoint_profile_destinations"
        :process-response="displayProfileDestinations" :show-loading="false"></endpoint-fetcher>
</template>

<script>
import EndpointFetcher from '@/components/common/EndpointFetcher.vue';
import ConfirmAndWait from '@/components/common/ConfirmAndWait.vue';
import { endpoints } from '@/components/common/api-config.js';
import { voiceToneOptions, writingStyleOptions } from '@/components/generator/GeneratorConfig.js';

const profile = window.profileData;

export default {
    name: 'AiPreferences',
    components: {
        EndpointFetcher,
        ConfirmAndWait,
    },
    data() {
        return {
            endpoint_generator_profile_detail: endpoints.generator_profile_detail(profile.pk),
            endpoint_profile_destinations: endpoints.profile_destinations(profile.pk),
            voice_tone_options: voiceToneOptions,
            writing_style_options: writingStyleOptions,
            destination_options: [],
            form: {
                profile: profile.pk,
                voice_tone: null,
                writing_style: null,
                use_agency_name: false,
                destination1: profile.destination1,
                destination2: profile.destination2,
                destination3: profile.destination3,
            },
            isPro: profile.plan === 'pro',
        }
    },
    methods: {
        displayProfileDetails(response) {
            if (response.status === 200) {
                this.form.use_agency_name = response.data.use_agency_name;
                this.form.voice_tone = response.data.voice_tone;
                this.form.writing_style = response.data.writing_style;
            }
        },
        displayProfileDestinations(response) {
            if (response.status === 200) {
                this.destination_options = response.data;
            }
        },
        savePreferences() {
            this.$refs.saveAiPreferences.submit();
        },
    },
    computed: {
        isDisabled() {
            return !this.isPro;
        }
    }
}
</script>
