<template>
    <div>
        <h5>Batch operations: </h5>
        <div>
            <a
                :href="uploadUrl"
            >
                <b-button
                    :disabled="is_exporting || !uploadUrl || (clist && clist.is_importing)"
                    class="m-1"
                    v-cloak
                    variant="light"
                >
                    {{ $t('uploadLabel') }}
                    <i class="fa fa-upload"></i>
                </b-button>
            </a>
            <b-button
                :disabled="is_exporting"
                @click="exportContactList()"
                class="m-1"
                v-cloak
                variant="light"
            >
                {{ $t('downloadLabel') }}
                <i class="fa fa-download"></i>
            </b-button>
            <span v-if="clist && is_exporting">
                {{ $t('creating') }}
                <i class="fa fa-refresh fa-spin"></i>
            </span>

            <span v-if="clist && clist.contact_list_file && !is_exporting">
                <a :href="clist.contact_list_file">
                    {{ $t('viewLastExport') }} ({{ export_date }})
                    <i class="fa fa-file-excel-o"></i>
                </a>
            </span>
        </div>
        <div>
            <span v-if="clist && clist.status==='ERROR'">
                <small class="text-danger">
                    {{ $t('importError') }}
                </small>
                <br>
            </span>

        </div>
    </div>
</template>

<script>
    import axios from '../../../setup/axios-setup'
    import Toast from '../../../toast.js';
    const toast = new Toast();
    import moment from 'moment-timezone';

    export default {
        name: 'contact-list-importer-exporter',
        props: {
            contactListId: {
                type: String,
                required: true,
            },
        },
        data: function () {
            return {
                clist: null,
                currentExport: null,
                export_date: null,
                is_exporting: false,
                profile: null,
            }
        },
        methods: {
            getContactList() {
                axios.get(`/api/contact_list/${this.contactListId}`, { emarketing: this.profile.id })
                    .then(response => {
                        this.clist = response.data;
                        if( this.clist.is_exporting || this.clist.is_importing ){
                            setTimeout(this.getContactList, 20000);
                        } else if (this.is_exporting) {
                            this.finishExporting(true)
                        }
                        this.export_date = this.clist.export_created ? moment.tz(
                            this.clist.export_created, "America/Toronto"
                        ).local().format("LLL") : '';
                    }, () => {
                        toast.error("Unable to export contact list.", "Error");
                        this.finishExporting()
                    }
                );
            },
            beginExporting() {
                this.is_exporting = true;
            },
            finishExporting(finishedOk=false) {
                this.is_exporting = false;
                if (finishedOk) {
                    window.location.reload()
                }
            },
            exportContactList() {
                toast.success("Please wait, we are generating a new export file.", "Exporting");
                this.beginExporting()

                const config = {
                    method: 'post',
                    url: `/api/contact_list/${this.clist.id}/export_contacts/`,
                    data: { emarketing: this.profile.id },
                }
                axios(config)
                    .then(() => {
                        this.getContactList();
                    }, () => {
                        toast.error("Unable to export contact list.", "Error");
                        this.finishExporting()
                    }
                );
            },
        },
        mounted: function () {
            this.profile = window.profileData;
            this.getContactList()
        },
        computed: {
            uploadUrl() {
                if(this.profile && this.clist) {
                    return `/${this.profile.slug}/emarketing/${this.clist.id}/import-contacts/`;
                } else {
                    return null
                }
            }
        },
        i18n: {
            messages: {
                en: {
                    creating: "Creating File",
                    downloadLabel: "Download contacts",
                    importError: "Import error on last upload",
                    uploadLabel: "Upload contacts",
                    viewLastExport: "View last export",
                },
                es: {
                    creating: "Creando archivo",
                    downloadLabel: "Descargar contactos",
                    importError: "Error en la última carga",
                    uploadLabel: "Subir contactos",
                    viewLastExport: "Ver último archivo exportado",
                },
                fr: {
                    creating: "Création d'un fichier",
                    downloadLabel: "Télécharger les contacts",
                    importError: "Erreur d'importation lors du dernier téléchargement",
                    uploadLabel: "Télécharger des contacts",
                    viewLastExport: "Afficher la dernière exportation",
                },
            }
        }
    }
</script>
