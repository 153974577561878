<template>
    <div class="row">
        <apps-connection-card-item v-for="app in apps" :key="app" :app_slug="app">
        </apps-connection-card-item>
    </div>
</template>

<script>
import { connectionConfigValues } from '@/components/apps-connection-config.js';
import AppsConnectionCardItem from '@/components/AppsConnectionCardItem.vue';

export default {
    name: 'ConnectionsDashboard',
    components: {
        AppsConnectionCardItem,
    },
    data() {
        return {
            apps: Object.values(connectionConfigValues).map(config => config.id),
        }
    }
}
</script>
