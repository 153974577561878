import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
    state: () => {
        // Remove applicaitons from profile basic data
        const profile = Object.fromEntries(
            Object.entries(window.profileData).filter(([key]) => key !== 'applications')
        );
        const defaultStatus = {
            installed: false,
            connected: false,
            connectURL: '',
        };
        // Facebook status using default and ignore inner data object
        const fbData = Object.fromEntries(
            Object.entries(window.profileData.applications.facebook).filter(([key]) => key !== 'data')
        );
        const facebook = { ...defaultStatus, ...fbData };

        // Instagram status using default and ignore inner data object
        const igData = Object.fromEntries(
            Object.entries(window.profileData.applications.instagram).filter(([key]) => key !== 'data')
        );
        const instagram =  { ...defaultStatus, ...igData };
        // LinkedIn status using default and ignore inner data object
        const liData = Object.fromEntries(
            Object.entries(window.profileData.applications.linkedin).filter(([key]) => key !== 'data')
        );
        const linkedin = { ...defaultStatus, ...liData };

        // Emarketing status using default and ignore inner data object
        const emarketing = Object.fromEntries(
            Object.entries(window.profileData.applications.emarketing).filter(([key]) => key !== 'data')
        );

        return {
            profile: profile || {},
            // Applications status to simplify reactivity
            facebook: facebook || {},
            instagram: instagram || {},
            linkedin: linkedin || {},
            emarketing: emarketing || {},
            // Applications data to simplify reactivity
            facebook_data: window.profileData.applications.facebook.data || {},
            instagram_data: window.profileData.applications.instagram.data || {},
            linkedin_data: window.profileData.applications.linkedin.data || {},
            emarketing_data: window.profileData.applications.emarketing.data || {},
        };
    }
});
