<template>
    <b-badge :variant="variant" v-if="plan">
        {{ label }}
    </b-badge>
</template>
<script>
    export default {
        name: 'PlanBadge',
        props: {
            plan: {
                type: String,
                required: true,
                validator(value) {
                    return ['', 'free', 'pro'].includes(value);
                }
            },
            size: {
                type: String,
                default: '',
                validator(value) {
                    return ['', 'xs', 'xxs', 'lg', 'xl'].includes(value);
                }
            }
        },
        computed: {
            label() {
                return this.$t(`common.${this.plan}`);
            },
            variant() {
                if(this.size){
                    return `billing-${this.plan}-${this.size}`;
                }
                return `billing-${this.plan}`;
            }
        }
    }
</script>
