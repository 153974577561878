<template>
  <endpoint-fetcher :endpoint="endpoint" v-model:fetchedData="onboardingVideos"
    :processResponse="response => response.data.results">
    <video-slider :videos="onboardingVideos" :videos-per-page="videosPerPage"></video-slider>
  </endpoint-fetcher>
</template>

<script>
import { endpoints } from '../common/api-config.js';
import VideoSlider from '../common/VideoSlider.vue';
import EndpointFetcher from '../common/EndpointFetcher.vue';

const profile = window.profileData;
const isMobile = window.innerWidth < 768;

export default {
  name: 'OnboardingVideos',
  components: {
    VideoSlider,
    EndpointFetcher
  },
  props: {
    videosPerPage: {
      type: Number,
      required: false,
      default: isMobile ? 1 : 2
    }
  },
  data() {
    return {
      onboardingVideos: [],
    };
  },
  computed: {
    endpoint() {
      return endpoints.onboarding_videos_list(profile.pk);
    }
  },
  watch: {
    onboardingVideos(newVideos) {
      if (newVideos.length > 0) {
        document.getElementById('header-no-videos').classList.add('d-none');
        document.getElementById('header-with-videos').classList.remove('d-none');
      }
    }
  }
};
</script>

<style>
#header-with-videos .accordion-toggle {
  padding: 0 0 4px 0;
  margin: 0 0 24px 0;
}
</style>
