import moment from "moment-timezone";

export const setDate = {
    now: () => {
        return moment();
    },
    add: (value, from = new Date(), period = "days") => {
        return moment(from).add(period, value);
    },
    subtract: (value, from = new Date(), period = "days") => {
        return moment(from).subtract(period, value);
    },
    parse: (dateString) => {
        // Try to parse the date string with moment
        const parsedDate = moment(dateString, true);
        // Check if it's a valid date
        if (parsedDate.isValid()) {
            return parsedDate;
        } else {
            return null;
        }
    },
};

export const formatDate = {
    parseWithTimeZone: (value, tz = "America/Toronto") => {
        return moment.tz(value, tz).local();
    },
    long: (value) => {
        return moment(value).format("YYYY-MM-DD");
    },
    longISO: (value) => {
        return moment(value).toISOString();
    },
    simple: (value) => {
        return moment(value).format("LLL");
    },
};
