<template>
    <h4>{{ campaign.name }}</h4>
    <ul class="list-unstyled">
        <li><span class="font-weight-bold">Campaign Type</span>: {{ campaign.campaign_type  }}</li>
        <li><span class="font-weight-bold">Country</span>: {{ campaign.country  }}</li>
        <li>
            <span class="font-weight-bold">Suppliers: </span>
            <a
                v-for="([pk, name], index) in suppliers"
                v-bind:key="pk"
                class="btn-link clickable"
                :class="{ 'font-weight-bolder': supplier && supplier.supplier_name === name }"
                @click="selectSupplier(pk)"
            >
                {{ name }}<span v-if="index < suppliers.length - 1">, </span>
            </a>
            <a
                v-if="suppliers && suppliers.length > 1"
                class="btn-link clickable"
                @click="selectSupplier(null)"
            >, View All </a>
            <span c-if="!suppliers || !suppliers.length">
                -
            </span>
        </li>
        <li><span class="font-weight-bold">Date</span>: {{ campaignDate }}</li>
    </ul>
    <b-table :items="items" :fields="fields" bordered></b-table>
</template>

<script>
    import { numToPercentage, numToString } from '@/utils/numbers';
    import { formatDate } from '@/utils/datetime'

    export default {
        props: {
            campaign: {
                type: Object,
                required: true,
            },
            supplier: {
                type: Object,
                required: true,
            },
        },
        data() {
            const { num_agent, num_click, num_open, num_sent } = this.campaign;
            return {
                agents: num_agent,
                clicks: num_click,
                opens: num_open,
                sent: num_sent,
                fields: [
                    { key: 'col1', label: 'Column 1', thStyle: { display: 'none' }, variant: 'active' },
                    { key: 'col2', label: 'Column 2', thStyle: { display: 'none' }, tdClass: "text-center" },
                    { key: 'col3', label: 'Column 3', thStyle: { display: 'none' }, variant: 'active' },
                    { key: 'col4', label: 'Column 4', thStyle: { display: 'none' }, tdClass: "text-center" },
                ],
            };
        },
        methods: {
            selectSupplier(supplierId) {
                const url = new URL(window.location.href);
                if (supplierId) {
                    url.searchParams.set('supplier', supplierId);
                } else {
                    url.search = ''
                }
                window.location.href = url.href;
            }
        },
        computed: {
            openPercentage() {
                const value = this.opens >= 0 && this.sent ? ((this.opens / this.sent) * 100) : 0
                return numToPercentage(value, { empty: '-' });
            },
            clickPercentage() {
                const value = this.clicks >= 0 && this.opens ? ((this.clicks / this.opens) * 100) : 0;
                return numToPercentage(value, { empty: '-' })
            },
            campaignDate() {
                return formatDate.long(this.campaign.date_published);
            },
            items() {
                return [
                    {
                        col1: 'Agents', col2: this.agents === 1 ? ' - ' : numToString(this.agents),
                        col3: 'Sent', col4: numToString(this.sent)
                    },
                    {
                        col1: 'Open', col2: numToString(this.opens),
                        col3: 'Clicks', col4: numToString(this.clicks) },
                    {
                        col1: 'Open (%)', col2: this.openPercentage,
                        col3: 'Clicks to Open (%)', col4: this.clickPercentage
                    },
                ];
            },
            suppliers() {
                const suppliers = this.campaign.suppliers || {};
                return Object.entries(suppliers);
            }
        },
    };
</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
</style>
