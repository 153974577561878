import { getQueryParams } from "@/utils/urls";

export const routes = {
    links: (campaignId, supplierId=undefined) => {
        return `/api/emailcampaigns/${campaignId}/links/` + getQueryParams({ supplier: supplierId });
    },
    preview: campaignId => `/api/emailcampaigns/${campaignId}/preview/`,
    stats: (campaignId, supplierId=undefined) => {
        return `/api/emailcampaigns/${campaignId}/stats/` + getQueryParams({ supplier: supplierId });
    },
}
